import {
  INICIA_AMBIENTE,
  INICIO_AMBIENTE_SUCESSO,
  INICIO_CENARIO_AMBIENTE_LOCAL,
  INICIO_CENARIO_AMBIENTE_LOCAL_SUCESSO,
  ADICIONA_PRIMEIRO_AMBIENTE_LOCAL,
  CLIQUE_ADICIONA_AMBIENTE_LOCAL,
  CLIQUE_ADICIONA_PONTO_ACESSO,
  CLIQUE_ADICIONA_SUB_AMBIENTE,
  ADICIONA_AMBIENTE_LOCAL,
  ADICIONA_PONTO_ACESSO,
  ADICIONA_SUB_AMBIENTE,
  PREENCHE_INFORMACAO_PONTO,
  REMOVE_PONTO_ACESSO_LOCAL,
  CONSULTA_CPF_ACESSO,
  CONSULTA_CPF_ACESSO_SUCESSO,
  OBTER_INFORMACAO_ACESSO_PONTO,
  REMOVE_AMBIENTE,
  ADICIONA_PESSOA_LOCAL,
  ADICIONA_PESSOA_LOCAL_SUCESSO,
  REMOVE_PESSOA_LOCAL,
  ADICIONA_PERFIL_LOCAL,
  REMOVE_PERFIL_LOCAL,
  REMOVE_PESSOA_LOCAL_FULL,
  ADICIONA_PESSOA_LOCAL_FULL,
  REMOVE_PESSOA_LOCAL_BLOQUEIO,
  ADICIONA_PESSOA_LOCAL_BLOQUEIO,
  ADICIONA_PESSOA_LOCAL_BLOQUEIO_SUCESSO,
  ADICIONA_PERIODO_LOCAL,
  REMOVE_PERIODO_LOCAL,
  EDITA_PERIODO_LOCAL,
  CANCELA_PERIODO_LOCAL,
  ATUALIZA_INFORMACAO_PONTO,
  CANCELA_INFORMACAO_PONTO,
  ADICIONA_GENERO_LOCAL,
  REMOVE_GENERO_LOCAL,
  ADICIONA_IDADE_LOCAL,
  REMOVE_IDADE_LOCAL,
  CONSULTA_CPF_ACESSO_FULL,
  CONSULTA_CPF_ACESSO_FULL_SUCESSO,
  CONSULTA_CPF_ACESSO_BLOQUEIO,
  CONSULTA_CPF_ACESSO_BLOQUEIO_SUCESSO,
  SALVA_AMBIENTES,
  SALVA_GESTAO_ACESSO,
  SALVA_GESTAO_ACESSO_SUCESSO,
  SALVA_AMBIENTES_SUCESSO,
  CLIQUE_BOTAO_TREEVIEW,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  HIDE_MESSAGE,
  ALTERACAO_CAMPO,
  OBTER_ACESSO_LOCAL_CONTROLADO,
  OBTER_ACESSO_LOCAL_CONTROLADO_SUCESSO,
  REMOVE_HORARIO_PERIODO,
  ALTERACAO_CHECK_TODOS_DIAS_SEMANA,
  ALTERACAO_CHECK_EM_QUALQUER_HORARIO,
  ALTERACAO_CHECK_INICIO_FIM_PROGRAMADO,
  ADICIONA_TODA_BASE_LOCAL,
  ADICIONA_TODA_BASE_SUCESSO,
  ADICIONA_PESSOA_LOCAL_FULL_SUCESSO,
  ADICIONA_PERFIL_LOCAL_SUCESSO,
  ADICIONA_GENERO_LOCAL_SUCESSO,
  ADICIONA_IDADE_LOCAL_SUCESSO,
  ADICIONA_PERIODO_LOCAL_SUCESSO,
  CONSULTA_CPF_PERIODO,
  CONSULTA_CPF_PERIODO_SUCESSO,
  VALIDACAO_CAMPO,
  NOVO_HORARIO,
  REMOVE_HORARIO,
  ATUALIZA_HORARIO,
  EXIBIR_ESTRUTURA_COPIA_ACESSOS,
  ATUALIZA_AMBIENTE_PORTARIA,
  ATUALIZA_CONTROLE_GARAGEM,
  LIMPA_VALIDACOES
} from 'constants/ActionTypes';

/*#############################################################################################*/
/* ------------------------------ Actions Fundamentais CRUD -----------------------------------*/

export const iniciaAmbiente = (tipo, temporario) => {
  return {
    type: INICIA_AMBIENTE,
    payload: { tipo, temporario }
  };
};

export const salvaAmbientes = (ambienteTela) => {
  var listaAmbientes = ambienteTela.listaAmbientesDoLocal.filter(x => x.descricaoAmbiente !== undefined);
  var idLocalControladoFixado = ambienteTela.idLocalControladoFixado;

  return {
    type: SALVA_AMBIENTES,
    payload: { idLocalControladoFixado, listaAmbientes }
  };
};

export const salvaGestaoAcesso = (ambienteTela) => {
  var listaAmbientes = ambienteTela.listaAmbientesDoLocal.filter(x => x.descricaoAmbiente !== undefined);
  var idLocalControladoFixado = ambienteTela.idLocalControladoFixado;
  var ehPerfisTemporarios = false;

  return {
    type: SALVA_GESTAO_ACESSO,
    payload: { idLocalControladoFixado, listaAmbientes, ehPerfisTemporarios }
  };
};

export const salvaGestaoAcessoTemporario = (ambienteTela) => {
  var listaAmbientes = ambienteTela.listaAmbientesDoLocal.filter(x => x.descricaoAmbiente !== undefined);
  var idLocalControladoFixado = ambienteTela.idLocalControladoFixado;
  var ehPerfisTemporarios = true;

  return {
    type: SALVA_GESTAO_ACESSO,
    payload: { idLocalControladoFixado, listaAmbientes, ehPerfisTemporarios }
  };
};

/*#############################################################################################*/
/* ---------------------------- Actions do Fluxo de Navegação ---------------------------------*/

export const iniciaCenarioAmbientePorLocalControlado = (name, object, value) => {
  var idLocal = value !== null && value !== undefined && value !== '' ? value.id : 0;

  return [{
    type: LIMPA_VALIDACOES,
  },
  {
    type: INICIO_CENARIO_AMBIENTE_LOCAL,
    payload: { idLocal }
  }]
}

export const obterAcessoLocalControladoPermanente = (name, object, value) => {
  var idLocal = value !== null && value !== undefined && value !== '' ? value.id : 0;
  var perfilTemporario = false;

  return {
    type: OBTER_ACESSO_LOCAL_CONTROLADO,
    payload: { idLocal, perfilTemporario }
  };
}

export const obterAcessoLocalControladoTemporario = (name, object, value) => {
  var idLocal = value !== null && value !== undefined && value !== '' ? value.id : 0;
  var perfilTemporario = true;

  return {
    type: OBTER_ACESSO_LOCAL_CONTROLADO,
    payload: { idLocal, perfilTemporario }
  };
}

/*#############################################################################################*/
/* --------------------------- Actions de respostas actions anteriores ------------------------*/

export const inicioAmbienteSucesso = (locais, perfis, usuariosDoCliente, tipo, perfilTemporario) => {

  if (locais.length === 1) {
    var idLocal = locais[0].id;

    return [{
      type: INICIO_AMBIENTE_SUCESSO,
      payload: { locais, perfis, usuariosDoCliente }
    },
    tipo === 'PONTO' ? {
      type: INICIO_CENARIO_AMBIENTE_LOCAL,
      payload: { idLocal }
    } :
      {
        type: OBTER_ACESSO_LOCAL_CONTROLADO,
        payload: { idLocal, perfilTemporario }
      }
    ]
  }

  return {
    type: INICIO_AMBIENTE_SUCESSO,
    payload: { locais, perfis, usuariosDoCliente }
  }
};

export const inicioCenarioAmbientePorLocalControladoSucesso = (ambientes, idLocal, contadorAmbiente, contadorPontoAcesso, equipamentos, controladoras) => {
  return {
    type: INICIO_CENARIO_AMBIENTE_LOCAL_SUCESSO,
    payload: { ambientes, idLocal, contadorAmbiente, contadorPontoAcesso, equipamentos, controladoras }
  };
}

export const obterAcessoLocalControladoSucesso = (ambientes, idLocal, contadorAmbiente, contadorPontoAcesso, pessoasAptasLocal, perfis) => {
  return {
    type: OBTER_ACESSO_LOCAL_CONTROLADO_SUCESSO,
    payload: { ambientes, idLocal, contadorAmbiente, contadorPontoAcesso, pessoasAptasLocal, perfis }
  };
}

export const salvaAmbientesSucesso = (mensagem) => {
  return {
    type: SALVA_AMBIENTES_SUCESSO,
    payload: mensagem
  };
}

export const salvaGestaoAcessoSucesso = (mensagem) => {
  return {
    type: SALVA_GESTAO_ACESSO_SUCESSO,
    payload: mensagem
  };
}

/*#############################################################################################*/
/* ---------------------------------- Outras Actions Auxiliares -------------------------------*/

export const cliqueBotaoTreeview = (instrucao) => {
  ////alert(instrucao);
  var tipoAcaoReducer = "";
  var retorno = null;

  if (instrucao === 'novoAmbiente') {
    tipoAcaoReducer = CLIQUE_ADICIONA_AMBIENTE_LOCAL;
  }
  else if (instrucao.indexOf('newPa-') !== -1) {
    tipoAcaoReducer = CLIQUE_ADICIONA_PONTO_ACESSO;
    retorno = { idAmbiente: parseInt(instrucao.replace('newPa-', '')) };
  }
  else if (instrucao.indexOf('newSa-') !== -1) {
    tipoAcaoReducer = CLIQUE_ADICIONA_SUB_AMBIENTE;
    retorno = { idAmbiente: parseInt(instrucao.replace('newSa-', '')) };
  }
  else {
    tipoAcaoReducer = CLIQUE_BOTAO_TREEVIEW;
  }

  return {
    type: tipoAcaoReducer,
    payload: retorno
  };
}

export const adicionaPrimeiroAmbienteLocal = (nomeAmbiente) => {

  if (nomeAmbiente !== undefined && nomeAmbiente !== null && nomeAmbiente !== '') {
    return [{
      type: ADICIONA_PRIMEIRO_AMBIENTE_LOCAL,
      payload: null
    },
    {
      type: VALIDACAO_CAMPO,
      payload: { erro: false, propriedadeValidada: 'textoPrimeiroAmbienteAdicionar', mensagemErro: '' }
    }]
  }
  else {
    return {
      type: VALIDACAO_CAMPO,
      payload: { erro: true, propriedadeValidada: 'textoPrimeiroAmbienteAdicionar', mensagemErro: 'Campo Obrigatório' }
    };
  }

}

export const preencherInformacoesPonto = (idPontoAcesso) => {
  return {
    type: PREENCHE_INFORMACAO_PONTO,
    payload: idPontoAcesso
  };
}

export const removerPontoAcesso = (idPontoAcesso) => {
  return {
    type: REMOVE_PONTO_ACESSO_LOCAL,
    payload: idPontoAcesso
  };
}

export const removerAmbiente = (idAmbiente) => {
  return {
    type: REMOVE_AMBIENTE,
    payload: idAmbiente
  };
}

export const atualizarInformacoesPonto = () => {
  return {
    type: ATUALIZA_INFORMACAO_PONTO,
    payload: null
  };
}

export const cancelarInformacoesPonto = () => {
  return {
    type: CANCELA_INFORMACAO_PONTO,
    payload: null
  };
}

export const adicionaAmbienteLocal = (nomeAmbiente) => {

  if (nomeAmbiente !== undefined && nomeAmbiente !== null && nomeAmbiente !== '') {
    return [{
      type: ADICIONA_AMBIENTE_LOCAL,
      payload: null
    },
    {
      type: VALIDACAO_CAMPO,
      payload: { erro: false, propriedadeValidada: 'textoAmbienteAdicionar', mensagemErro: '' }
    }]
  }
  else {
    return {
      type: VALIDACAO_CAMPO,
      payload: { erro: true, propriedadeValidada: 'textoAmbienteAdicionar', mensagemErro: 'Campo Obrigatório' }
    };
  }



  /*     return {
        type: ADICIONA_AMBIENTE_LOCAL,
        payload: null
      };  */
}

export const atualizaAmbientePortaria = (object, value) => {
  var nomeCheck = [object.target.id].toString();
  nomeCheck = nomeCheck === "" ? [object.target.name].toString() : nomeCheck;

  var indexUnderline = nomeCheck.indexOf("_");
  var idAtualizacao = parseInt(nomeCheck.substring(indexUnderline + 1));
  var campo = nomeCheck.replace(nomeCheck.substring(indexUnderline), "");
  var valor = value;

  return {
    type: ATUALIZA_AMBIENTE_PORTARIA,
    payload: { idAtualizacao, valor }
  }
};

export const atualizaControleGaragem = (idTemporarioAmbiente) => {
  return {
    type: ATUALIZA_CONTROLE_GARAGEM,
    payload: { idTemporarioAmbiente }
  }
};

export const adicionaPontoAcesso = (nomePontoAcesso) => {

  if (nomePontoAcesso !== undefined && nomePontoAcesso !== null && nomePontoAcesso !== '') {
    return [{
      type: ADICIONA_PONTO_ACESSO,
      payload: null
    },
    {
      type: VALIDACAO_CAMPO,
      payload: { erro: false, propriedadeValidada: 'textoPontoAcessoAdicionar', mensagemErro: '' }
    }]
  }
  else {
    return {
      type: VALIDACAO_CAMPO,
      payload: { erro: true, propriedadeValidada: 'textoPontoAcessoAdicionar', mensagemErro: 'Campo Obrigatório' }
    };
  }

  /*     return {
        type: ADICIONA_PONTO_ACESSO,
        payload: null
      };     */
}

export const adicionaSubAmbiente = (nomeSubAmbiente) => {

  if (nomeSubAmbiente !== undefined && nomeSubAmbiente !== null && nomeSubAmbiente !== '') {
    return [{
      type: ADICIONA_SUB_AMBIENTE,
      payload: null
    },
    {
      type: VALIDACAO_CAMPO,
      payload: { erro: false, propriedadeValidada: 'textoSubAmbienteAdicionar', mensagemErro: '' }
    }]
  }
  else {
    return {
      type: VALIDACAO_CAMPO,
      payload: { erro: true, propriedadeValidada: 'textoSubAmbienteAdicionar', mensagemErro: 'Campo Obrigatório' }
    };
  }

  /*     return {
        type: ADICIONA_SUB_AMBIENTE,
        payload: null
      };   */
}

export const onChangeValue = (e) => {

  return {
    type: ALTERACAO_CAMPO,
    payload: e
  }
};

export const exibirMensagemErro = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};


/*#############################################################################################*/
/* ---------------------------------- Actions gestão de acesso -------------------------------*/

export const consultaAcessoCPF = (cpf) => {
  let cpfAjustado = cpf.target.value;
  cpfAjustado = cpfAjustado.replace(/-/g, "");

  return {
    type: CONSULTA_CPF_ACESSO,
    payload: cpfAjustado
  };
};

export const consultaCpfSucesso = (correspondenciaCpf) => {
  return {
    type: CONSULTA_CPF_ACESSO_SUCESSO,
    payload: correspondenciaCpf
  }
};

export const consultaAcessoCPFFull = (cpf) => {
  let cpfAjustado = cpf.target.value;
  cpfAjustado = cpfAjustado.replace(/-/g, "");

  return {
    type: CONSULTA_CPF_ACESSO_FULL,
    payload: cpfAjustado
  };
};

export const consultaCpfFullSucesso = (correspondenciaCpf) => {
  return {
    type: CONSULTA_CPF_ACESSO_FULL_SUCESSO,
    payload: correspondenciaCpf
  }
};

export const consultaAcessoCPFBloqueio = (cpf) => {
  let cpfAjustado = cpf.target.value;
  cpfAjustado = cpfAjustado.replace(/-/g, "");

  return {
    type: CONSULTA_CPF_ACESSO_BLOQUEIO,
    payload: cpfAjustado
  };
};

export const consultaCpfBloqueioSucesso = (correspondenciaCpf) => {
  return {
    type: CONSULTA_CPF_ACESSO_BLOQUEIO_SUCESSO,
    payload: correspondenciaCpf
  }
};

export const consultaAcessoCPFPeriodo = (cpf) => {
  let cpfAjustado = cpf.target.value;
  cpfAjustado = cpfAjustado.replace(/-/g, "");

  return {
    type: CONSULTA_CPF_PERIODO,
    payload: cpfAjustado
  };
};

export const consultaCpfPeriodoSucesso = (correspondenciaCpf) => {
  return {
    type: CONSULTA_CPF_PERIODO_SUCESSO,
    payload: correspondenciaCpf
  }
};

export const obterInformacaoAcessoPonto = (idPontoAcesso) => {
  return {
    type: OBTER_INFORMACAO_ACESSO_PONTO,
    payload: idPontoAcesso
  };
}
export const adicionarPessoa = (tela) => {

  const pessoaSelecionada = tela.listaDePessoasDoClienteCompleto.filter(x => x.id === tela.idPessoaSelecionadaDiretamente);

  if (tela.acessosGenero.filter(x => x.nomePerfil === 'TODOS OS PERFIS' && x.sexo !== pessoaSelecionada[0].sexo).length > 0) {
    return exibirMensagemErro('Existe uma regra de Gênero impeditiva para adição desta pessoa.');
  }
  else {
    if (tela.idPessoaSelecionadaDiretamente !== 0) {
      return {
        type: ADICIONA_PESSOA_LOCAL,
        payload: tela
      };
    }
    else {
      return exibirMensagemErro('Selecione uma pessoa para prosseguir');
    }
  }
}

export const validaAdicionarPessoaSucesso = () => {

  return {
    type: ADICIONA_PESSOA_LOCAL_SUCESSO,
    payload: null
  };
}

export const validaAdicionarTodaBaseSucesso = () => {

  return {
    type: ADICIONA_TODA_BASE_SUCESSO,
    payload: null
  };
}

export const validaAdicaoAcssoFullSucesso = () => {

  return {
    type: ADICIONA_PESSOA_LOCAL_FULL_SUCESSO,
    payload: null
  };
}

export const removerPessoa = (idPessoa) => {
  return {
    type: REMOVE_PESSOA_LOCAL,
    payload: idPessoa
  };
}

export const adicionarPerfil = (tela) => {
  if (tela.idPerfilSelecionado === '') {
    return exibirMensagemErro('Informe um perfil para prosseguir');
  }

  return {
    type: ADICIONA_PERFIL_LOCAL,
    payload: tela
  };
}

export const removerPerfil = (idPerfil) => {
  return {
    type: REMOVE_PERFIL_LOCAL,
    payload: idPerfil
  };
}

export const validaAdicaoPerfilSucesso = () => {

  return {
    type: ADICIONA_PERFIL_LOCAL_SUCESSO,
    payload: null
  };
}

export const adicionarGenero = (tela, ehAcessoTemporario) => {

  if (tela.idPerfilGeneroSelecionado === '' && tela.acessosPessoa.filter(x => x.todaBase).length === 0) {
    if (ehAcessoTemporario) {
      return exibirMensagemErro('Informe um perfil para prosseguir');
    }
    else {
      return exibirMensagemErro('Antes de adicionar um gênero de forma global defina o acesso para "Toda a Base de Usúarios" na primeira guia');
    }

  }
  else {
    return {
      type: ADICIONA_GENERO_LOCAL,
      payload: tela
    };
  }

}

export const removerGenero = (idGenero, idPerfil, removeTodos) => {
  return {
    type: REMOVE_GENERO_LOCAL,
    payload: { idGenero, idPerfil, removeTodos }
  };
}
export const validaAdicaoGeneroSucesso = () => {

  return {
    type: ADICIONA_GENERO_LOCAL_SUCESSO,
    payload: null
  };
}
export const adicionarIdade = (tela, ehAcessoTemporario) => {

  if (tela.idPerfilIdadeSelecionado === '' && tela.acessosPessoa.filter(x => x.todaBase).length === 0) {
    if (ehAcessoTemporario) {
      return exibirMensagemErro('Informe um perfil para prosseguir');
    }
    else {
      return exibirMensagemErro('Antes de adicionar critério de idade de forma global defina o acesso para "Toda a Base de Usúarios" na primeira guia');
    }
  }
  else {
    return {
      type: ADICIONA_IDADE_LOCAL,
      payload: tela
    };
  }
}
export const removerIdade = (idIdade) => {
  return {
    type: REMOVE_IDADE_LOCAL,
    payload: idIdade
  };
}
export const validaAdicaoIdadeSucesso = () => {

  return {
    type: ADICIONA_IDADE_LOCAL_SUCESSO,
    payload: null
  };
}

export const adicionarPessoaFull = (tela) => {

  if (tela.idPerfilAcessoFullSelecionado === 0 && tela.idPessoaSelecionadaDiretamenteAcessoFull === 0) {

    if(tela.tipoAcessoFullSelecionado === 'PERFIL') {
      return exibirMensagemErro('Selecione um Perfil para prosseguir');
    }
    else {
      return exibirMensagemErro('Selecione uma Pessoa para prosseguir');
    }
  }
  else {
    return {
      type: ADICIONA_PESSOA_LOCAL_FULL,
      payload: tela
    };
  }
}

export const removerPessoaFull = (idPessoa) => {
  return {
    type: REMOVE_PESSOA_LOCAL_FULL,
    payload: idPessoa
  };
}
/* export const adicionarPessoaBloqueio = (tela) => {
  return {
    type: ADICIONA_PESSOA_LOCAL_BLOQUEIO,
    payload: tela
  };
} */

export const adicionarPessoaBloqueio = (tela) => {

  if (tela.idPerfilBloqueioFullSelecionado === 0 && tela.idPessoaSelecionadaDiretamenteBloqueioFull === 0) {

    if(tela.tipoBloqueioFullSelecionado === 'PERFIL') {
      return exibirMensagemErro('Selecione um Perfil para prosseguir');
    }
    else {
      return exibirMensagemErro('Selecione uma Pessoa para prosseguir');
    }
  }
  else {
    return {
      type: ADICIONA_PESSOA_LOCAL_BLOQUEIO,
      payload: tela
    };
  }
}


export const validaAdicaoAcessoBloqueioSucesso = () => {
  return {
    type: ADICIONA_PESSOA_LOCAL_BLOQUEIO_SUCESSO,
    payload: null
  };
}
export const removerPessoaBloqueio = (idPessoa) => {
  return {
    type: REMOVE_PESSOA_LOCAL_BLOQUEIO,
    payload: idPessoa
  };
}
export const adicionarPeriodo = (tela, ehAcessoTemporario) => {

  if (tela.idPerfilPeriodoSelecionado === '' && tela.acessosPessoa.filter(x => x.todaBase).length === 0) {
    if (ehAcessoTemporario) {
      return exibirMensagemErro('Informe um perfil para prosseguir');
    }
  }

  return {
    type: ADICIONA_PERIODO_LOCAL,
    payload: tela
  };
}
export const removerPeriodo = (idPeriodo) => {
  return {
    type: REMOVE_PERIODO_LOCAL,
    payload: idPeriodo
  };
}
export const validaAdicaoPeriodoSucesso = () => {

  return {
    type: ADICIONA_PERIODO_LOCAL_SUCESSO,
    payload: null
  };
}
export const removerHorario = (idHorario) => {
  return {
    type: REMOVE_HORARIO_PERIODO,
    payload: idHorario
  };
}
export const editarPeriodo = (idPeriodo) => {
  return {
    type: EDITA_PERIODO_LOCAL,
    payload: idPeriodo
  };
}
export const cancelaPeriodoLocal = () => {
  return {
    type: CANCELA_PERIODO_LOCAL,
    payload: null
  };
}
export const onChangeCheckTodosDiasSemana = (object, value) => {

  return {
    type: ALTERACAO_CHECK_TODOS_DIAS_SEMANA,
    payload: value
  }
}
export const onChangeCheckEmQualquerHorario = (object, value) => {

  return {
    type: ALTERACAO_CHECK_EM_QUALQUER_HORARIO,
    payload: value
  }
}
export const onChangeCheckInicioFimProgramado = (object, value) => {

  return {
    type: ALTERACAO_CHECK_INICIO_FIM_PROGRAMADO,
    payload: value
  }
}

export const novoHorario = () => {
  return {
    type: NOVO_HORARIO
  };
};


export const removeHorario = (indice) => {
  return {
    type: REMOVE_HORARIO,
    payload: indice
  };
};

export const exibirEstruturaCopiaAcessos = (idPontoSelecionado) => {
  return {
    type: EXIBIR_ESTRUTURA_COPIA_ACESSOS,
    payload: idPontoSelecionado
  };
};

export const atualizaHorario = (e) => {
  var key = e.which || e.keyCode || e.charCode;
  var indexUnderline = e.target.name.indexOf("_");
  var indiceAtualizacao = parseInt(e.target.name.substring(indexUnderline + 1));
  var campo = e.target.name.replace(e.target.name.substring(indexUnderline), "");
  var valor = e.target.value;
  var removeDoisPontos = e.target.defaultValue.replace(e.target.value, '') === ':';

  if (!removeDoisPontos && valor.length === 2) {
    valor = valor + ":";
  }

  return {
    type: ATUALIZA_HORARIO,
    payload: { campoAtualizar: campo, indice: indiceAtualizacao, valor: valor }
  };
};

export const adicionarTodaBase = (tela) => {
  var teste = teste;

  return {
    type: ADICIONA_TODA_BASE_LOCAL,
    payload: tela
  };
}