import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { SALVA_PERFIL, INICIA_PERFIL, BUSCA_PERFIL_EDICAO, REMOVE_PERFIL, LISTA_PERFIL,
  EXIBE_DETALHE_ESTRUTURA_LOCAL_PERFIL } from "constants/ActionTypes";
import requisicaoApi from 'util/Api';
import {  userSignOut } from "actions/Auth";
import { showAuthMessage, 
         exibirMensagemErro, 
         salvaPerfilSucesso,
         removePerfilSucesso, 
         inicioPerfilSucesso, 
         iniciaPerfil,
         buscaPerfilEdicaoSucesso,
         listaPerfilSucesso,
         exibeDetalheEstruturaLocalPerfilSucesso } from "actions/Perfil";
import moment from 'moment';

/*#################################################################################################################*/
/* --------------------------------------------- Requests do tipo GET ---------------------------------------------*/         

  const getPerfis = async () =>
    await requisicaoApi.get('/perfis')
    .then(resposta => resposta)
    .catch(error => error);

  const getClientes = async () =>
    await requisicaoApi.get('/clientes')
    .then(resposta => resposta)
    .catch(error => error);    
    
  const getLocaisControlados = async () =>
    await requisicaoApi.get('/locais?Cliente=&DataInicioControle=&DataFimControle='+moment(new Date()).format('YYYY-MM-DD')+'&NomeLocalControlado=&TipoLocal=')
    .then(resposta => resposta)
    .catch(error => error);  

  const getPerfilEdicao = async (id) =>
    await requisicaoApi.get('/perfis/' + id)
    .then(resposta => resposta)
    .catch(error => error);  
    
    const getListaPerfil = async (nomeFiltro) => 
    await requisicaoApi.get('/perfis?Nome=' +  nomeFiltro)
    .then(resposta => resposta)
    .catch(error => error);  

    const getAcessoAmbienteLocal = async (id) =>
    await requisicaoApi.get('/gestaoAcesso/local/' + id + '/' + false)
        .then(resposta => resposta)
        .catch(error => error);  

/*#################################################################################################################*/
/* ------------------------------------- Requests do tipo POST, PUT E DELETE --------------------------------------*/     

  const adicionaPerfilRequest = async (perfil) => await requisicaoApi.post('/perfis', 
  {
    "id": perfil.id,
    "idCliente": perfil.idCliente,
    "descricaoPerfil": perfil.descricaoPerfil,
    "ativo": perfil.ativo,
    "tipoPerfil": "ACESSO_FISICO",
    "caracteristicaPerfil": perfil.caracteristicaPerfil,
    "exibeEmpresaCargoPortaria": perfil.exibeEmpresaCargoPortaria,
    "ehDuploCheck": perfil.ehDuploCheck,
    "listaPerfisAmbientesOrigem": perfil.listaAmbientesOrigem.length === 0 ? [] : perfil.listaAmbientesOrigem, 
  })
  .then(resposta => resposta)
  .catch(error => error);

  const atualizaPerfilRequest = async (perfil) => await requisicaoApi.put('/perfis', 
  {
    "id": perfil.id,
    "idCliente": perfil.idCliente,
    "descricaoPerfil": perfil.descricaoPerfil,
    "observacao": perfil.observacao,
    "ativo": perfil.ativo,
    "tipoPerfil": "ACESSO_FISICO",
    "caracteristicaPerfil": perfil.caracteristicaPerfil,
    "exibeEmpresaCargoPortaria": perfil.exibeEmpresaCargoPortaria,
    "ehDuploCheck": perfil.ehDuploCheck,
    "listaPerfisAmbientesOrigem": perfil.listaAmbientesOrigem.length === 0 ? [] : perfil.listaAmbientesOrigem, 
  })
  .then(resposta => resposta)
  .catch(error => error);

  const deletePerfilRequest = async (id) =>
  await requisicaoApi.delete('/perfis?id=' + id)
  .then(resposta => resposta)
  .catch(error => error);    

/*#################################################################################################################*/
/* --------------------------------------------- Functions do tipo GET --------------------------------------------*/
/*#################################################################################################################*/   

  function* fnIniciaPerfil() {
    try {
      const listagemGrid = yield call(getPerfis);

      if(!listagemGrid.isAxiosError && listagemGrid.data)
      {
        const clientes = yield call(getClientes);

        if(clientes.data) {

          const locais = yield call(getLocaisControlados);

          if(locais.data) {
            yield put(inicioPerfilSucesso(listagemGrid.data, clientes.data, locais.data));
          }
          
        }
      }
      else {
        if(listagemGrid.response.status === 401 && listagemGrid.response.config.xsrfHeaderName === "X-XSRF-TOKEN")
        {
          yield put(userSignOut());
        }
        else
        {
          yield put(showAuthMessage('Ocorreu um erro ao listar perfis'));
        }
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  }

  function* fnBuscaPerfilEdicao({ payload }) {
    try {
      const id = payload;
      const retorno = yield call(getPerfilEdicao, id);
  
      if(!retorno.isAxiosError && retorno.data)
      {
        yield put(buscaPerfilEdicaoSucesso(retorno.data));
      }
      else
      {
        yield put(showAuthMessage('Ocorreu um erro ao retornar os dados do perfil: ' + id));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  }

  function* fnListaPerfil({ payload }) {
    try {
      const nomeFiltro = payload.nome;
      const retorno = yield call(getListaPerfil, nomeFiltro);
  
      if(!retorno.isAxiosError && retorno.data)
      {
        yield put(listaPerfilSucesso(retorno.data));
      }
      else
      {
        yield put(showAuthMessage('Ocorreu um erro ao retornar os dados de perfil'));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }    
  } 
  
/*#################################################################################################################*/
/* ---------------------------------- Functions do tipo POST, PUT E DELETE ----------------------------------------*/
/*#################################################################################################################*/  


  function* fnSalvaPerfil({ payload }) {
  try {

    const perfil = payload;
    var resultado = "";

      if(perfil.id === 0) {
        const retorno = yield call(adicionaPerfilRequest, perfil);
        resultado = retorno;
      }
      else {
        const retorno = yield call(atualizaPerfilRequest, perfil);
        resultado = retorno;
      }

    if(!resultado.isAxiosError && resultado.data)
    {
      if(resultado.data.sucesso) {
        yield put(iniciaPerfil());
        yield put(salvaPerfilSucesso(resultado.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(resultado.data.mensagem));
      }
    }
    else
    {
      if(resultado.response.data)
      {
        if(resultado.response.data.inconsistencias.length > 0)
        {
          const inconsistencia = resultado.response.data.inconsistencias[0];
          var propCorrigir = inconsistencia.propriedade;
          var indexPonto = propCorrigir.indexOf(".");
          propCorrigir = propCorrigir.substr(indexPonto + 1);
          
          yield put(exibirMensagemErro(inconsistencia.mensagem + " - " + propCorrigir));
        }
        else
        {
          yield put(exibirMensagemErro("Ocorreu um erro interno."));
        }
      }
      else {
        yield put(exibirMensagemErro("Ocorreu um erro interno."));
      }
    }         

  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnExibeDetalheEstruturaLocalPerfil({ payload }) {
  try {
    const local = payload.idLocal;
    const retorno = yield call(getAcessoAmbienteLocal, local);
    const objetoRetorno = retorno.data;
    const arvoreLocal = objetoRetorno.ambientes;

    if(objetoRetorno && arvoreLocal) {
      yield put(exibeDetalheEstruturaLocalPerfilSucesso(arvoreLocal, local));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}  

function* fnRemovePerfil({ payload }) {
  try {

    const id = payload;
    const retorno = yield call(deletePerfilRequest, id);
    
    if(!retorno.isAxiosError && retorno.data)
    {
      if(retorno.data.sucesso) {
        yield put(iniciaPerfil());
        yield put(removePerfilSucesso(retorno.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(retorno.data.mensagem));
      }
    }
    else
    {
      if(retorno.response.data.mensagem)
      {
        yield put(exibirMensagemErro(retorno.response.data.inconsistencias[0].mensagem));
      }
      else
      {
        yield put(exibirMensagemErro("Erro desconhecido"));
      }
    }        
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

/*#################################################################################################################*/
/* -------------------------------------------- Chamadas de Funções -----------------------------------------------*/
/*#################################################################################################################*/  

export function* iniciaAcoesPerfil() {
  yield takeEvery(INICIA_PERFIL, fnIniciaPerfil);
}

export function* salvaPerfil() {
  yield takeEvery(SALVA_PERFIL, fnSalvaPerfil);
}

export function* buscaPerfilEdicao() {
  yield takeEvery(BUSCA_PERFIL_EDICAO, fnBuscaPerfilEdicao);
}

export function* removePerfil() {
  yield takeEvery(REMOVE_PERFIL, fnRemovePerfil);
}

export function* listaPerfil() {
  yield takeEvery(LISTA_PERFIL, fnListaPerfil)
}

export function* exibeDetalheEstruturaLocalPerfil() {
  yield takeEvery(EXIBE_DETALHE_ESTRUTURA_LOCAL_PERFIL, fnExibeDetalheEstruturaLocalPerfil);
}

/*#################################################################################################################*/
/* ------------------------------------------- Registro de Chamadas -----------------------------------------------*/
/*#################################################################################################################*/  

export default function* rootSaga() {
  yield all([fork(iniciaAcoesPerfil), 
             fork(salvaPerfil), 
             fork(buscaPerfilEdicao),
             fork(removePerfil),
             fork(listaPerfil),
             fork(exibeDetalheEstruturaLocalPerfil)]);
}