import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { INICIA_AMBIENTE, INICIO_CENARIO_AMBIENTE_LOCAL, ADICIONA_PESSOA_LOCAL, ADICIONA_PERIODO_LOCAL, ADICIONA_PESSOA_LOCAL_BLOQUEIO,ADICIONA_IDADE_LOCAL, ADICIONA_GENERO_LOCAL, ADICIONA_PERFIL_LOCAL, ADICIONA_TODA_BASE_LOCAL, ADICIONA_PESSOA_LOCAL_FULL, SALVA_AMBIENTES, CONSULTA_CPF_ACESSO, CONSULTA_CPF_ACESSO_FULL, OBTER_ACESSO_LOCAL_CONTROLADO, SALVA_GESTAO_ACESSO, CONSULTA_CPF_ACESSO_BLOQUEIO, CONSULTA_CPF_PERIODO } from "constants/ActionTypes";
import requisicaoApi from 'util/Api';
import { userSignOut } from "actions/Auth";
import {
    showAuthMessage,
    exibirMensagemErro,
    inicioAmbienteSucesso,
    inicioCenarioAmbientePorLocalControladoSucesso,
    salvaAmbientesSucesso,
    salvaGestaoAcessoSucesso,
    consultaCpfSucesso,
    consultaCpfFullSucesso,
    obterAcessoLocalControladoSucesso,
    consultaCpfBloqueioSucesso,
    validaAdicionarPessoaSucesso,
    validaAdicionarTodaBaseSucesso,
    validaAdicaoAcssoFullSucesso,
    validaAdicaoAcessoBloqueioSucesso,
    validaAdicaoPerfilSucesso,
    validaAdicaoGeneroSucesso,
    validaAdicaoIdadeSucesso,
    validaAdicaoPeriodoSucesso,
    consultaCpfPeriodoSucesso
} from "actions/Ambiente";

/*#################################################################################################################*/
/* --------------------------------------------- Requests do tipo GET ---------------------------------------------*/

const getLocaisControlados = async () =>
    await requisicaoApi.get('/locais')
        .then(resposta => resposta)
        .catch(error => error);

const getAmbienteLocal = async (id) =>
    await requisicaoApi.get('/ambientes/local/' + id)
        .then(resposta => resposta)
        .catch(error => error);    

const getAcessoAmbienteLocal = async (idLocal, perfilTemporario) =>
    await requisicaoApi.get('/gestaoAcesso/local/' + idLocal + '/' + perfilTemporario)
        .then(resposta => resposta)
        .catch(error => error);  
    
const getEquipamentos = async () =>
        await requisicaoApi.get('/equipamentos/terminais')
        .then(resposta => resposta)
        .catch(error => error);  

        const getControladoras = async () =>
        await requisicaoApi.get('/equipamentos/controladoras')
        .then(resposta => resposta)
        .catch(error => error);  
        
const getCpf = async (cpf) =>
        await requisicaoApi.get('/util/pessoaFisica/' + cpf)
        .then(resposta => resposta)
        .catch(error => error);   

const getListaPerfisPermanentes = async () => 
        await requisicaoApi.get('/perfis/cliente/permanente')
        .then(resposta => resposta)
        .catch(error => error);   

const getListaPerfisTemporarios = async () => 
        await requisicaoApi.get('/perfis/cliente/temporario')
        .then(resposta => resposta)
        .catch(error => error);           

const getUsuariosPlataforma = async () =>
        await requisicaoApi.get('/usuariosPlataforma/novo')
        .then(resposta => resposta)
        .catch(error => error);        


/*#################################################################################################################*/
/* ------------------------------------- Requests do tipo POST, PUT E DELETE --------------------------------------*/

const salvaAmbienteLoteRequest = async (idLocalControladoFixado, listaAmbientes) => await requisicaoApi.post('/ambientes/salvaremlote', 
{
  "idLocalControlado": idLocalControladoFixado,
  "ambientes": listaAmbientes,
})
.then(resposta => resposta)
.catch(error => error);

const salvaGestaoAcessoRequest = async (idLocalControladoFixado, listaAmbientes, ehPerfisTemporarios) => await requisicaoApi.post('/gestaoAcesso/salvaracessoemlote', 
{
  "idLocalControlado": idLocalControladoFixado,
  "ambientes": listaAmbientes,
  "ehPerfisTemporarios": ehPerfisTemporarios
})
.then(resposta => resposta)
.catch(error => error);

const validaAdicaoPessoaRequest = async (idPessoaAdicionar, todaBase, acessosPessoa, sexo) => await requisicaoApi.post('/gestaoAcesso/ValidaAdicionaPessoa', 
{
  "idPessoaAdicionar": idPessoaAdicionar,
  "todaBase": todaBase,
  "acessosPessoa": acessosPessoa,
  "sexo": sexo
})
.then(resposta => resposta)
.catch(error => error);

const validaAdicaoAcssoFullRequest = async (idPessoaAdicionar, idPerfil, ehPerfil, acessosFull) => await requisicaoApi.post('/gestaoAcesso/validaAdicionaAcessoFull', 
{
  "idPessoaAcessoBloqueio": idPessoaAdicionar,
  "idPerfilAcessoBloqueio": idPerfil,
  "ehPerfil": ehPerfil,
  "acessosPessoaPerfilFull": acessosFull,
})
.then(resposta => resposta)
.catch(error => error);

const validaAdicaoBloqueioFullRequest = async (idPessoaAdicionar, idPerfil, ehPerfil, acessosBloqueio) => await requisicaoApi.post('/gestaoAcesso/validaAdicionaBloqueioFull', 
{
  "idPessoaAcessoBloqueio": idPessoaAdicionar,
  "idPerfilAcessoBloqueio": idPerfil,
  "ehPerfil": ehPerfil,
  "acessosPessoaPerfilBloqueio": acessosBloqueio,
})
.then(resposta => resposta)
.catch(error => error);

const validaAdicaoPefilRequest = async (idPerfilAdicionar, acessosPerfil) => await requisicaoApi.post('/gestaoAcesso/validaAdicionaPerfil', 
{
  "idPerfilAdicionar": idPerfilAdicionar,
  "acessosPerfil": acessosPerfil,
})
.then(resposta => resposta)
.catch(error => error);

const validaAdicaoGeneroRequest = async (sexo, idPerfilGeneroSelecionado, acessosGenero) => await requisicaoApi.post('/gestaoAcesso/validaAdicionaGenero', 
{
  "sexo": sexo,
  "idPerfilGenero": idPerfilGeneroSelecionado,
  "acessosGenero": acessosGenero,
})
.then(resposta => resposta)
.catch(error => error);

const validaAdicaoIdadeRequest = async (idadeMinima, idadeMaxima, idPerfilIdadeSelecionado, acessosIdade) => await requisicaoApi.post('/gestaoAcesso/validaAdicionaIdade', 
{
  "idadeMinima": idadeMinima,
  "idadeMaxima": idadeMaxima,
  "idPerfilIdade": idPerfilIdadeSelecionado,
  "acessosIdade": acessosIdade,
})
.then(resposta => resposta)
.catch(error => error);

const validaAdicaoPeriodoRequest = async (parametro) => await requisicaoApi.post('/gestaoAcesso/validaAdicionaPeriodo', 
{
  "todosDiasSelecionado":  parametro.todosDiasSelecionado,
  "segundaAtivo": parametro.segundaAtivo,
  "tercaAtivo": parametro.tercaAtivo,
  "quartaAtivo": parametro.quartaAtivo,
  "quintaAtivo": parametro.quintaAtivo,
  "sextaAtivo": parametro.sextaAtivo,
  "sabadoAtivo": parametro.sabadoAtivo,
  "domingoAtivo": parametro.domingoAtivo,
  "emQualquerHorario": parametro.emQualquerHorario,
  "horarioInicio": parametro.horarioInicio,
  "horarioFim": parametro.horarioFim,
  "horarioInicioSegundo": parametro.horarioInicioSegundo,
  "horarioFimSegundo": parametro.horarioFimSegundo,
  "horarioInicioTerceiro": parametro.horarioInicioTerceiro,
  "horarioFimTerceiro": parametro.horarioFimTerceiro,
  "inicioFimProgramado": parametro.inicioFimProgramado,
  "dataInicioControle": parametro.dataInicioControle,
  "dataFimControle": parametro.dataFimControle,
  "idPerfilPeriodo": parametro.idPerfilPeriodoSelecionado,
  "acessosPeriodo": parametro.acessosPeriodo,
  "minutoInicio": parametro.minutoInicio,
  "minutoFim": parametro.minutoFim,
  "minutoInicioSegundo": parametro.minutoInicioSegundo,
  "minutoFimSegundo": parametro.minutoFimSegundo,
  "minutoInicioTerceiro": parametro.minutoInicioTerceiro,
  "minutoFimTerceiro": parametro.minutoFimTerceiro,
  "ehPerfilPeriodo": parametro.tipoAcessoPeriodoSelecionado == 'PERFIL' ? true:false,
  "idPessoaPeriodo": parametro.idPessoaSelecionadaDiretamentePeriodo,
})
.then(resposta => resposta)
.catch(error => error);

/*#################################################################################################################*/
/* --------------------------------------------- Functions do tipo GET --------------------------------------------*/
/*#################################################################################################################*/

function* fnIniciaAmbiente({ payload }) {
    try {
        const tipo = payload.tipo;
        const perfilTemporario = payload.temporario;
        const listagemGrid = yield call(getLocaisControlados);
        const perfis = perfilTemporario ? yield call(getListaPerfisTemporarios) : yield call(getListaPerfisPermanentes);
        let listaPerfis = perfis.data;
        listaPerfis = listaPerfis.filter(x => x.tipoPerfil === 'ACESSO_FISICO')
        let usuariosDoCliente = [];

        listaPerfis = perfilTemporario ? listaPerfis.filter(x => x.caracteristicaPerfil === 'TEMPORARIO') : listaPerfis.filter(x => x.caracteristicaPerfil !== 'TEMPORARIO');

        if (tipo === 'GESTAO') {
           usuariosDoCliente = yield call(getUsuariosPlataforma);
        }

        if (!listagemGrid.isAxiosError && listagemGrid.data) {

            yield put(inicioAmbienteSucesso(listagemGrid.data, listaPerfis, tipo === 'GESTAO' ? usuariosDoCliente.data : [], tipo, perfilTemporario));
            
        }
        else {
            if (listagemGrid.response.status === 401 && listagemGrid.response.config.xsrfHeaderName === "X-XSRF-TOKEN") {
                yield put(userSignOut());
            }
            else {
                yield put(showAuthMessage('Ocorreu um erro ao listar modelos'));
            }
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

function* fnCenarioAmbientePorLocalControlado({ payload }) {
    try {
      const local = payload.idLocal;
      const retorno = yield call(getAmbienteLocal, local);
      const objetoRetorno = retorno.data;
      const ambientes = objetoRetorno.ambientes;
      const contadorAmbiente = objetoRetorno.contadorAmbiente;
      const contadorPontoAcesso = objetoRetorno.contadorPontoAcesso;
      const equipamentos = yield call(getEquipamentos);
      const controladoras = yield call(getControladoras);

      if(!retorno.isAxiosError && objetoRetorno) {
        yield put(inicioCenarioAmbientePorLocalControladoSucesso(ambientes, local, contadorAmbiente, contadorPontoAcesso, equipamentos.data, controladoras.data));
      }
      else {
        yield put(exibirMensagemErro("Operação não realizada!. Ocorreu um erro interno."));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  }

  function* fnObterAcessoLocalControlado({ payload }) {
    try {
      const local = payload.idLocal;
      const perfilTemporario = payload.perfilTemporario;
      const retorno = yield call(getAcessoAmbienteLocal, local, perfilTemporario);
      const objetoRetorno = retorno.data;
      const pessoasAptasLocal = objetoRetorno.pessoasDoLocal;
      const ambientes = objetoRetorno.ambientes;
      const contadorAmbiente = objetoRetorno.contadorAmbiente;
      const contadorPontoAcesso = objetoRetorno.contadorPontoAcesso;
      const perfis = objetoRetorno.perfisDoLocal;
      if(objetoRetorno) {
        yield put(obterAcessoLocalControladoSucesso(ambientes, local, contadorAmbiente, contadorPontoAcesso, pessoasAptasLocal, perfis));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  }

  function* fnConsultaCpf({ payload }) {
    try {
      const cpf = payload;
      const retorno = yield call(getCpf, cpf);
  
      if(!retorno.isAxiosError && retorno.data)
      {
        yield put(consultaCpfSucesso(retorno.data));
      }
      else
      {
        yield put(showAuthMessage('Ocorreu um erro ao consultar o CPF: ' + cpf));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  } 

  
  function* fnConsultaCpfFull({ payload }) {
    try {
      const cpf = payload;
      const retorno = yield call(getCpf, cpf);
  
      if(!retorno.isAxiosError && retorno.data)
      {
        yield put(consultaCpfFullSucesso(retorno.data));
      }
      else
      {
        yield put(showAuthMessage('Ocorreu um erro ao consultar o CPF: ' + cpf));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  } 
    
  function* fnConsultaCpfBloqueio({ payload }) {
    try {
      const cpf = payload;
      const retorno = yield call(getCpf, cpf);
  
      if(!retorno.isAxiosError && retorno.data)
      {
        yield put(consultaCpfBloqueioSucesso(retorno.data));
      }
      else
      {
        yield put(showAuthMessage('Ocorreu um erro ao consultar o CPF: ' + cpf));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  } 

  function* fnConsultaCpfPeriodo({ payload }) {
    try {
      const cpf = payload;
      const retorno = yield call(getCpf, cpf);
  
      if(!retorno.isAxiosError && retorno.data)
      {
        yield put(consultaCpfPeriodoSucesso(retorno.data));
      }
      else
      {
        yield put(showAuthMessage('Ocorreu um erro ao consultar o CPF: ' + cpf));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  } 

/*#################################################################################################################*/
/* ---------------------------------- Functions do tipo POST, PUT E DELETE ----------------------------------------*/
/*#################################################################################################################*/  


function* fnSalvaAmbientes({ payload }) {
  try {
    const parametro = payload;
    var resultado = "";

    const retorno = yield call(salvaAmbienteLoteRequest, parametro.idLocalControladoFixado, parametro.listaAmbientes );
    resultado = retorno;
    
    if(!resultado.isAxiosError && resultado.data)
    {
      if(resultado.data.sucesso) {
        yield put(salvaAmbientesSucesso(resultado.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(resultado.data.mensagem));
      }
    }
    else
    {
      if(resultado.response.data)
      {
        if(resultado.response.data.inconsistencias.length > 0)
        {
          const inconsistencia = resultado.response.data.inconsistencias[0];
          var propCorrigir = inconsistencia.propriedade;
          var indexPonto = propCorrigir.indexOf(".");
          propCorrigir = propCorrigir.substr(indexPonto + 1);
          
          yield put(exibirMensagemErro(inconsistencia.mensagem + " - " + propCorrigir));
        }
        else
        {
          yield put(exibirMensagemErro("Ocorreu um erro interno."));
        }
      }
      else {
        yield put(exibirMensagemErro("Ocorreu um erro interno."));
      }
    }            

  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnSalvaGestaoAcesso({ payload }) {
  try {
    const parametro = payload;
    var resultado = "";

    const retorno = yield call(salvaGestaoAcessoRequest, parametro.idLocalControladoFixado, parametro.listaAmbientes, parametro.ehPerfisTemporarios );
    resultado = retorno;
    
    if(!resultado.isAxiosError && resultado.data)
    {
      if(resultado.data.sucesso) {
        yield put(salvaGestaoAcessoSucesso(resultado.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(resultado.data.mensagem));
      }
    }
    else
    {
      if(resultado.response.data)
      {
        if(resultado.response.data.inconsistencias.length > 0)
        {
          const inconsistencia = resultado.response.data.inconsistencias[0];
          var propCorrigir = inconsistencia.propriedade;
          var indexPonto = propCorrigir.indexOf(".");
          propCorrigir = propCorrigir.substr(indexPonto + 1);
          
          yield put(exibirMensagemErro(inconsistencia.mensagem + " - " + propCorrigir));
        }
        else
        {
          yield put(exibirMensagemErro("Ocorreu um erro interno."));
        }
      }
      else {
        yield put(exibirMensagemErro("Ocorreu um erro interno."));
      }
    }            

  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnValidaAdicaoPessoa({ payload }) {
  try {
    const parametro = payload;
    const pessoaSelecionada = parametro.listaDePessoasDoClienteCompleto.filter(x => x.id === parametro.idPessoaSelecionadaDiretamente);
    var resultado = "";

    const retorno = yield call(validaAdicaoPessoaRequest, parametro.idPessoaSelecionadaDiretamente, false, parametro.acessosPessoa, pessoaSelecionada[0].sexo );
    resultado = retorno;
    
    if(!resultado.isAxiosError && resultado.data)
    {
      if(resultado.data.sucesso) {
        yield put(validaAdicionarPessoaSucesso(resultado.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(resultado.data.mensagem));
      }
    }
    else
    {
      if(resultado.response.data)
      {
        if(resultado.response.data.inconsistencias.length > 0)
        {
          const inconsistencia = resultado.response.data.inconsistencias[0];
          var propCorrigir = inconsistencia.propriedade;
          var indexPonto = propCorrigir.indexOf(".");
          propCorrigir = propCorrigir.substr(indexPonto + 1);
          
          yield put(exibirMensagemErro(inconsistencia.mensagem + " - " + propCorrigir));
        }
        else
        {
          yield put(exibirMensagemErro("Ocorreu um erro interno."));
        }
      }
      else {
        yield put(exibirMensagemErro("Ocorreu um erro interno."));
      }
    }            

  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnValidaAdicaoTodaBase({ payload }) {
  try {
    const parametro = payload;
    var resultado = "";

    const retorno = yield call(validaAdicaoPessoaRequest, parametro.idPessoaAdicionar, true, parametro.acessosPessoa );
    resultado = retorno;
    
    if(!resultado.isAxiosError && resultado.data)
    {
      if(resultado.data.sucesso) {
        yield put(validaAdicionarTodaBaseSucesso(resultado.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(resultado.data.mensagem));
      }
    }
    else
    {
      if(resultado.response.data)
      {
        if(resultado.response.data.inconsistencias.length > 0)
        {
          const inconsistencia = resultado.response.data.inconsistencias[0];
          var propCorrigir = inconsistencia.propriedade;
          var indexPonto = propCorrigir.indexOf(".");
          propCorrigir = propCorrigir.substr(indexPonto + 1);
          
          yield put(exibirMensagemErro(inconsistencia.mensagem + " - " + propCorrigir));
        }
        else
        {
          yield put(exibirMensagemErro("Ocorreu um erro interno."));
        }
      }
      else {
        yield put(exibirMensagemErro("Ocorreu um erro interno."));
      }
    }            

  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnValidaAdicaoAcessoFull({ payload }) {
  try {
    const parametro = payload;
    var resultado = "";
    var ehPerfil = parametro.tipoAcessoFullSelecionado === "PERFIL";

    const retorno = yield call(validaAdicaoAcssoFullRequest, parametro.idPessoaSelecionadaDiretamenteAcessoFull, parametro.idPerfilAcessoFullSelecionado, ehPerfil, parametro.acessosPessoaFull );
    resultado = retorno;
    
    if(!resultado.isAxiosError && resultado.data)
    {
      if(resultado.data.sucesso) {
        yield put(validaAdicaoAcssoFullSucesso(resultado.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(resultado.data.mensagem));
      }
    }
    else
    {
      if(resultado.response.data)
      {
        if(resultado.response.data.inconsistencias.length > 0)
        {
          const inconsistencia = resultado.response.data.inconsistencias[0];
          var propCorrigir = inconsistencia.propriedade;
          var indexPonto = propCorrigir.indexOf(".");
          propCorrigir = propCorrigir.substr(indexPonto + 1);
          
          yield put(exibirMensagemErro(inconsistencia.mensagem + " - " + propCorrigir));
        }
        else
        {
          yield put(exibirMensagemErro("Ocorreu um erro interno."));
        }
      }
      else {
        yield put(exibirMensagemErro("Ocorreu um erro interno."));
      }
    }            

  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnValidaAdicaoAcessoBloqueio({ payload }) {
  try {
    const parametro = payload;
    var resultado = "";
    var ehPerfil = parametro.tipoBloqueioFullSelecionado === "PERFIL";

    const retorno = yield call(validaAdicaoBloqueioFullRequest, parametro.idPessoaSelecionadaDiretamenteBloqueioFull, parametro.idPerfilBloqueioFullSelecionado, ehPerfil, parametro.acessosPessoaBloqueio );
    resultado = retorno;
    
    if(!resultado.isAxiosError && resultado.data)
    {
      if(resultado.data.sucesso) {
        yield put(validaAdicaoAcessoBloqueioSucesso(resultado.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(resultado.data.mensagem));
      }
    }
    else
    {
      if(resultado.response.data)
      {
        if(resultado.response.data.inconsistencias.length > 0)
        {
          const inconsistencia = resultado.response.data.inconsistencias[0];
          var propCorrigir = inconsistencia.propriedade;
          var indexPonto = propCorrigir.indexOf(".");
          propCorrigir = propCorrigir.substr(indexPonto + 1);
          
          yield put(exibirMensagemErro(inconsistencia.mensagem + " - " + propCorrigir));
        }
        else
        {
          yield put(exibirMensagemErro("Ocorreu um erro interno."));
        }
      }
      else {
        yield put(exibirMensagemErro("Ocorreu um erro interno."));
      }
    }            

  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnValidaAdicaoPerfil({ payload }) {
  try {
    const parametro = payload;
    var resultado = "";
  
    const retorno = yield call(validaAdicaoPefilRequest, parametro.idPerfilSelecionado, parametro.acessosPerfil);
    resultado = retorno;
    
    if(!resultado.isAxiosError && resultado.data)
    {
      if(resultado.data.sucesso) {
        yield put(validaAdicaoPerfilSucesso(resultado.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(resultado.data.mensagem));
      }
    }
    else
    {
      if(resultado.response.data)
      {
        if(resultado.response.data.inconsistencias.length > 0)
        {
          const inconsistencia = resultado.response.data.inconsistencias[0];
          var propCorrigir = inconsistencia.propriedade;
          var indexPonto = propCorrigir.indexOf(".");
          propCorrigir = propCorrigir.substr(indexPonto + 1);
          
          yield put(exibirMensagemErro(inconsistencia.mensagem + " - " + propCorrigir));
        }
        else
        {
          yield put(exibirMensagemErro("Ocorreu um erro interno."));
        }
      }
      else {
        yield put(exibirMensagemErro("Ocorreu um erro interno."));
      }
    }            

  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnValidaAdicaoGenero({ payload }) {
  try {
    const parametro = payload;
    var resultado = "";
  
    const retorno = yield call(validaAdicaoGeneroRequest, parametro.generoSelecionado, parametro.idPerfilGeneroSelecionado, parametro.acessosGenero);
    resultado = retorno;
    
    if(!resultado.isAxiosError && resultado.data)
    {
      if(resultado.data.sucesso) {
        yield put(validaAdicaoGeneroSucesso(resultado.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(resultado.data.mensagem));
      }
    }
    else
    {
      if(resultado.response.data)
      {
        if(resultado.response.data.inconsistencias.length > 0)
        {
          const inconsistencia = resultado.response.data.inconsistencias[0];
          var propCorrigir = inconsistencia.propriedade;
          var indexPonto = propCorrigir.indexOf(".");
          propCorrigir = propCorrigir.substr(indexPonto + 1);
          
          yield put(exibirMensagemErro(inconsistencia.mensagem + " - " + propCorrigir));
        }
        else
        {
          yield put(exibirMensagemErro("Ocorreu um erro interno."));
        }
      }
      else {
        yield put(exibirMensagemErro("Ocorreu um erro interno."));
      }
    }            

  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnValidaAdicaoIdade({ payload }) {
  try {
    const parametro = payload;
    var resultado = "";
  
    const retorno = yield call(validaAdicaoIdadeRequest, parametro.idadeMinima, parametro.idadeMaxima, parametro.idPerfilIdadeSelecionado, parametro.acessosIdade);
    resultado = retorno;
    
    if(!resultado.isAxiosError && resultado.data)
    {
      if(resultado.data.sucesso) {
        yield put(validaAdicaoIdadeSucesso(resultado.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(resultado.data.mensagem));
      }
    }
    else
    {
      if(resultado.response.data)
      {
        if(resultado.response.data.inconsistencias.length > 0)
        {
          const inconsistencia = resultado.response.data.inconsistencias[0];
          var propCorrigir = inconsistencia.propriedade;
          var indexPonto = propCorrigir.indexOf(".");
          propCorrigir = propCorrigir.substr(indexPonto + 1);
          
          yield put(exibirMensagemErro(inconsistencia.mensagem + " - " + propCorrigir));
        }
        else
        {
          yield put(exibirMensagemErro("Ocorreu um erro interno."));
        }
      }
      else {
        yield put(exibirMensagemErro("Ocorreu um erro interno."));
      }
    }            

  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fnValidaAdicaoPeriodo({ payload }) {
  try {
    const parametro = payload;
    var resultado = "";
  
    const retorno = yield call(validaAdicaoPeriodoRequest, parametro);
    resultado = retorno;
    
    if(!resultado.isAxiosError && resultado.data)
    {
      if(resultado.data.sucesso) {
        yield put(validaAdicaoPeriodoSucesso(resultado.data.mensagem));
      }
      else {
        yield put(exibirMensagemErro(resultado.data.mensagem));
      }
    }
    else
    {
      if(resultado.response.data)
      {
        if(resultado.response.data.inconsistencias.length > 0)
        {
          const inconsistencia = resultado.response.data.inconsistencias[0];
          var propCorrigir = inconsistencia.propriedade;
          var indexPonto = propCorrigir.indexOf(".");
          propCorrigir = propCorrigir.substr(indexPonto + 1);
          
          yield put(exibirMensagemErro(inconsistencia.mensagem + " - " + propCorrigir));
        }
        else
        {
          yield put(exibirMensagemErro("Ocorreu um erro interno."));
        }
      }
      else {
        yield put(exibirMensagemErro("Ocorreu um erro interno."));
      }
    }            

  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

/*#################################################################################################################*/
/* -------------------------------------------- Chamadas de Funções -----------------------------------------------*/
/*#################################################################################################################*/

export function* iniciaAcoesModelo() {
    yield takeEvery(INICIA_AMBIENTE, fnIniciaAmbiente);
}

export function* iniciaCenarioAmbientePorLocalControlado() {
    yield takeEvery(INICIO_CENARIO_AMBIENTE_LOCAL, fnCenarioAmbientePorLocalControlado);
  }

  export function* obterAcessoLocalControlado() {
    yield takeEvery(OBTER_ACESSO_LOCAL_CONTROLADO, fnObterAcessoLocalControlado);
  }

  export function* salvaAmbientesBackend() {
    yield takeEvery(SALVA_AMBIENTES, fnSalvaAmbientes);
  }

  export function* salvaGestaoAcessoBackend() {
    yield takeEvery(SALVA_GESTAO_ACESSO, fnSalvaGestaoAcesso);
  }  

  export function* consultarCpfAcesso() {
    yield takeEvery(CONSULTA_CPF_ACESSO, fnConsultaCpf);
  }

  export function* consultarCpfAcessoFull() {
    yield takeEvery(CONSULTA_CPF_ACESSO_FULL, fnConsultaCpfFull);
  }

  export function* consultarCpfAcessoBloqueio() {
    yield takeEvery(CONSULTA_CPF_ACESSO_BLOQUEIO, fnConsultaCpfBloqueio);
  }

  export function* consultarCpfAcessoPeriodo() {
    yield takeEvery(CONSULTA_CPF_PERIODO, fnConsultaCpfPeriodo);
  }

  export function* validaAdicaoPessoa() {
    yield takeEvery(ADICIONA_PESSOA_LOCAL, fnValidaAdicaoPessoa);
  }
  
  export function* validaAdicaoTodaBase() {
    yield takeEvery(ADICIONA_TODA_BASE_LOCAL, fnValidaAdicaoTodaBase);
  }
  
  export function* validaAdicaoAcessoFull() {
    yield takeEvery(ADICIONA_PESSOA_LOCAL_FULL, fnValidaAdicaoAcessoFull);
  }

  export function* validaAdicaoAcessoBloqueio() {
    yield takeEvery(ADICIONA_PESSOA_LOCAL_BLOQUEIO, fnValidaAdicaoAcessoBloqueio);
  }

  export function* validaAdicaoPerfil() {
    yield takeEvery(ADICIONA_PERFIL_LOCAL, fnValidaAdicaoPerfil);
  }

  export function* validaAdicaoGenero() {
    yield takeEvery(ADICIONA_GENERO_LOCAL, fnValidaAdicaoGenero);
  }

  export function* validaAdicaoIdade() {
    yield takeEvery(ADICIONA_IDADE_LOCAL, fnValidaAdicaoIdade);
  }

  export function* validaAdicaoPeriodo() {
    yield takeEvery(ADICIONA_PERIODO_LOCAL, fnValidaAdicaoPeriodo);
  }
   
/*#################################################################################################################*/
/* ------------------------------------------- Registro de Chamadas -----------------------------------------------*/
/*#################################################################################################################*/

export default function* rootSaga() {
    yield all([fork(iniciaAcoesModelo),
        fork(iniciaCenarioAmbientePorLocalControlado),
        fork(salvaAmbientesBackend),
        fork(salvaGestaoAcessoBackend),
        fork(consultarCpfAcesso),
        fork(consultarCpfAcessoFull),
        fork(obterAcessoLocalControlado),
        fork(consultarCpfAcessoBloqueio),
        fork(consultarCpfAcessoPeriodo),
        fork(validaAdicaoPessoa),
        fork(validaAdicaoTodaBase), 
        fork(validaAdicaoAcessoFull),
        fork(validaAdicaoAcessoBloqueio),
        fork(validaAdicaoPerfil),
        fork(validaAdicaoGenero),
        fork(validaAdicaoIdade),
        fork(validaAdicaoPeriodo)]);
}