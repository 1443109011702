import { cpfMask, horarioMask, cnpjMask, telefoneMask, cepMask, ipMask, dateMask } from 'luxFw/transformations/Mascaras';
import { tiposComunicacaoModelo, formascomunicacaoEquipamentoCliente, tiposCheckin, tiposPontoAcesso } from '../app/constantes/Listas';
import { preparaListaParaCombo, retornaDescricaoPorValorListaPadrao } from 'luxFw/transformations/Common';
import {
  INICIA_AMBIENTE,
  INICIO_AMBIENTE_SUCESSO,
  INICIO_CENARIO_AMBIENTE_LOCAL,
  INICIO_CENARIO_AMBIENTE_LOCAL_SUCESSO,
  ADICIONA_PRIMEIRO_AMBIENTE_LOCAL,
  CLIQUE_ADICIONA_AMBIENTE_LOCAL,
  CLIQUE_ADICIONA_PONTO_ACESSO,
  CLIQUE_ADICIONA_SUB_AMBIENTE,
  ADICIONA_AMBIENTE_LOCAL,
  ADICIONA_PONTO_ACESSO,
  ADICIONA_SUB_AMBIENTE,
  SALVA_AMBIENTES,
  SALVA_AMBIENTES_SUCESSO,
  SALVA_GESTAO_ACESSO_SUCESSO,
  SALVA_GESTAO_ACESSO,
  PREENCHE_INFORMACAO_PONTO,
  REMOVE_PONTO_ACESSO_LOCAL,
  REMOVE_AMBIENTE,
  ADICIONA_PESSOA_LOCAL_SUCESSO,
  REMOVE_PESSOA_LOCAL,
  ADICIONA_PESSOA_LOCAL_FULL_SUCESSO,
  REMOVE_PESSOA_LOCAL_FULL,
  ADICIONA_PESSOA_LOCAL_BLOQUEIO_SUCESSO,
  REMOVE_PESSOA_LOCAL_BLOQUEIO,
  ADICIONA_PERFIL_LOCAL_SUCESSO,
  REMOVE_PERFIL_LOCAL,
  ADICIONA_GENERO_LOCAL_SUCESSO,
  REMOVE_GENERO_LOCAL,
  ADICIONA_IDADE_LOCAL_SUCESSO,
  REMOVE_IDADE_LOCAL,
  ADICIONA_PERIODO_LOCAL_SUCESSO,
  REMOVE_PERIODO_LOCAL,
  ATUALIZA_INFORMACAO_PONTO,
  CANCELA_INFORMACAO_PONTO,
  CONSULTA_CPF_ACESSO,
  CONSULTA_CPF_ACESSO_SUCESSO,
  OBTER_INFORMACAO_ACESSO_PONTO,
  INIT_URL,
  HIDE_MESSAGE,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  ON_SHOW_ERROR_MESSAGE,
  SHOW_MESSAGE,
  ALTERACAO_CAMPO,
  ALTERACAO_COMBO,
  ALTERACAO_CHECK,
  ALTERACAO_DATEPICKER,
  ALTERACAO_CAMPO_MASCARA_CEP,
  ALTERACAO_CAMPO_MASCARA_CNPJ,
  ALTERACAO_CAMPO_MASCARA_CPF,
  ALTERACAO_CAMPO_MASCARA_DATE,
  ALTERACAO_CAMPO_MASCARA_TELEFONE,
  ALTERACAO_CAMPO_MASCARA_IP,
  CONSULTA_CPF_ACESSO_FULL,
  CONSULTA_CPF_ACESSO_FULL_SUCESSO,
  CONSULTA_CPF_ACESSO_BLOQUEIO,
  CONSULTA_CPF_ACESSO_BLOQUEIO_SUCESSO,
  OBTER_ACESSO_LOCAL_CONTROLADO,
  OBTER_ACESSO_LOCAL_CONTROLADO_SUCESSO,
  REMOVE_HORARIO_PERIODO,
  EDITA_PERIODO_LOCAL,
  CANCELA_PERIODO_LOCAL,
  ALTERACAO_CHECK_TODOS_DIAS_SEMANA,
  ALTERACAO_CHECK_EM_QUALQUER_HORARIO,
  ALTERACAO_CHECK_INICIO_FIM_PROGRAMADO,
  ADICIONA_TODA_BASE_SUCESSO,
  CONSULTA_CPF_PERIODO,
  CONSULTA_CPF_PERIODO_SUCESSO,
  NOVO_HORARIO,
  REMOVE_HORARIO,
  ATUALIZA_HORARIO,
  EXIBIR_ESTRUTURA_COPIA_ACESSOS,
  ATUALIZA_AMBIENTE_PORTARIA,
  ATUALIZA_CONTROLE_GARAGEM,
} from "constants/ActionTypes";

const INIT_STATE = {

  horariosNovo: [
    {
      id: 0,
      idTemporario: 0,
      horarioInicial: '',
      horarioFinal: '',
    }
  ],

  // Propriedades Globais
  loader: false,
  alertMessage: '',
  showMessageSuccess: false,
  showMessageError: false,
  edicao: false,
  listaAmbientesDoLocal: [],
  contadorPontoAcesso: 0,
  contadorAmbiente: 0,

  // Campos do Formulario
  id: 0,
  idLocalControladoFixado: 0,
  nomeLocalControladoFixado: '',
  textoPrimeiroAmbienteAdicionar: '',
  textoAmbienteAdicionar: '',
  textoSubAmbienteAdicionar: '',
  textoPontoAcessoAdicionar: '',
  novoAmbiente: false,
  idAmbienteAddPontoAcesso: null,
  idAmbienteAddSubAmbiente: null,
  idPontoClonar: 0,
  idPontoClonado: 0,

  // Combos
  listaDeLocaisControlados: [],
  listaDeEquipamentos: [],
  listaDeControladoras: [],
  listaDeFormasComunicacao: [],
  listaDeTiposDePontoAcesso: [],
  listaDeTiposCheckin: [],

  // Campos formulário ponto de acesso
  idEquipamento: 0,
  idEquipamentoControladora: 0,
  caminhoComunicServer: '',
  formaComunicacao: 0,
  ipEquipamento: '',
  ipControladora: '',
  nomePontoAcesso: '',
  tipoPontoAcesso: 0,
  tipoCheckin: 0,
  ehUzerPassPremium: false,
  idPontoAcessoSelecionado: 0,
  possuiPerfilEspecificoGenero: false,
  possuiPerfilEspecificoIdade: false,
  clonaConfiguracoes: false,
  exibeEstruturaCopiaAcessos: false,
  pontoHerancaMarcado: '',

  // Campos gestão de acesso

  idPessoaSelecionadaDiretamente: 0,
  idPessoaSelecionadaDiretamenteAcessoFull: 0,
  idPerfilAcessoFullSelecionado: 0,
  idPerfilBloqueioFullSelecionado: 0,
  idPessoaSelecionadaDiretamenteBloqueioFull: 0,

  idPontoGestaoAcesso: '',
  listaDePerfis: [],
  acessosPessoa: [],
  acessosPerfil: [],
  acessosGenero: [],
  acessosIdade: [],
  acessosPessoaFull: [],
  acessosPessoaBloqueio: [],
  acessosPeriodo: [],

  nomePontoGestaoAcesso: '',
  numeroCpfAdicionar: '',
  nomePessoaAdicionar: '',
  idPessoaAdicionar: '',

  idPerfilSelecionado: 0,
  idPerfilGeneroSelecionado: 0,
  idPerfilIdadeSelecionado: 0,
  generoSelecionado: 'FEMININO',
  idadeMinima: 0,
  idadeMaxima: '',

  numeroCpfAdicionarFull: '',
  nomePessoaAdicionarFull: '',
  idPessoaAdicionarFull: '',

  numeroCpfAdicionarBloqueio: '',
  nomePessoaAdicionarBloqueio: '',
  idPessoaAdicionarBloqueio: '',

  idPerfilPeriodoSelecionado: 0,
  todosDiasSelecionado: true,
  segundaAtivo: true,
  tercaAtivo: true,
  quartaAtivo: true,
  quintaAtivo: true,
  sextaAtivo: true,
  sabadoAtivo: true,
  domingoAtivo: true,
  emQualquerHorario: true,
  horarioInicio: '',
  minutoInicio: '',
  minutoFim: '',
  horarioFim: '',
  horarioInicioSegundo: '',
  horarioFimSegundo: '',
  minutoInicioSegundo: '',
  minutoFimSegundo: '',
  horarioInicioTerceiro: '',
  horarioFimTerceiro: '',
  minutoInicioTerceiro: '',
  minutoFimTerceiro: '',
  inicioFimProgramado: false,
  dataInicioControle: null,
  dataFimControle: null,
  idTemporarioPeriodo: 0,
  idPeriodoSelecionado: 0,
  horarios: [],

  idPerfilAcessoFullSelecionado: 0,
  tipoAcessoFullSelecionado: 'PERFIL',

  idPerfilBloqueioFullSelecionado: 0,
  tipoBloqueioFullSelecionado: 'PERFIL',

  numeroCpfAdicionarPeriodo: '',
  nomePessoaAdicionarPeriodo: '',
  tipoAcessoPeriodoSelecionado: 'PERFIL',

  listaDePessoasDoClienteCompleto: [],
  listaDePessoasDoCliente: [],
  listaDePessoasDoLocal: [],
  listaDePessoasDoLocalCompleto: [],
  listaIdsTreeviewExpandidos: [],
  listaDePontosParaClonagemTecnicaCompleta: [],
  listaDePontosParaClonagemTecnica: [],
  listaDePontosHeranca: [],
  listaDePontosReplicacao: []
};

export default (state = INIT_STATE, action) => {
  const atualizarPontoAcessoAssincrono = (subAmbientes, idPontoAtualizar) => {
    var ambientes = subAmbientes.filter(x => x.descricaoAmbiente !== undefined);

    for (var indice in ambientes) {
      let objAmbiente = ambientes[indice];
      let possuiPontoAcesso = (objAmbiente.pontosAcesso && objAmbiente.pontosAcesso.length > 0);

      if (possuiPontoAcesso) {
        var pontosSelecionados = objAmbiente.pontosAcesso.filter(x => x.idTemporario === idPontoAtualizar);

        if (pontosSelecionados && pontosSelecionados.length > 0) {
          let objPonto = pontosSelecionados[0];
          objPonto.acessosPessoa = state.acessosPessoa;
          objPonto.acessosPerfil = state.acessosPerfil;
          objPonto.acessosGenero = state.acessosGenero;
          objPonto.acessosIdade = state.acessosIdade;
          objPonto.acessosPessoaFull = state.acessosPessoaFull;
          objPonto.acessosPessoaBloqueio = state.acessosPessoaBloqueio;
          objPonto.acessosPeriodo = state.acessosPeriodo;
          break;
        }
      }
      let possuiSubAmbiente = (objAmbiente.subAmbientes && objAmbiente.subAmbientes.length > 0);

      if (possuiSubAmbiente) {
        objAmbiente.subAmbientes = atualizarPontoAcessoAssincrono(objAmbiente.subAmbientes, idPontoAtualizar);
      }
    }

    return ambientes;
  };

  function atualizarGestaoAcessoLocal() {
    let idPonto = parseInt(state.idPontoGestaoAcesso)
    let arrayAtualizado = atualizarPontoAcessoAssincrono(state.listaAmbientesDoLocal, idPonto);

    return {
      ...state,
      loader: false,
      listaAmbientesDoLocal: [...state.listaAmbientesDoLocal, arrayAtualizado],
      numeroCpfAdicionar: '',
      nomePessoaAdicionar: '',
      idPessoaAdicionar: '',
      idPerfilSelecionado: '',
      idPerfilGeneroSelecionado: '',
      generoSelecionado: 'FEMININO',
      idPerfilIdadeSelecionado: '',
      idadeMinima: '',
      idadeMaxima: '',
      numeroCpfAdicionarFull: '',
      nomePessoaAdicionarFull: '',
      idPessoaAdicionarFull: '',
      numeroCpfAdicionarBloqueio: '',
      nomePessoaAdicionarBloqueio: '',
      idPessoaAdicionarBloqueio: '',
      idPerfilPeriodoSelecionado: '',
      todosDiasSelecionado: true,
      segundaAtivo: true,
      tercaAtivo: true,
      quartaAtivo: true,
      quintaAtivo: true,
      sextaAtivo: true,
      sabadoAtivo: true,
      domingoAtivo: true,
      emQualquerHorario: true,
      horarioInicio: '',
      horarioFim: '',
      horarioInicioSegundo: '',
      horarioFimSegundo: '',
      horarioInicioTerceiro: '',
      horarioFimTerceiro: '',
      inicioFimProgramado: false,
      dataInicioControle: null,
      dataFimControle: null,
      idTemporarioPeriodo: 0,
      idPeriodoSelecionado: 0,
      idPerfilAcessoFullSelecionado: 0,
      idPerfilBloqueioFullSelecionado: 0,
      minutoInicio: '',
      minutoFim: '',
      minutoInicioSegundo: '',
      minutoFimSegundo: '',
      minutoInicioTerceiro: '',
      minutoFimTerceiro: '',
      tipoAcessoPeriodoSelecionado: "PERFIL",
      numeroCpfAdicionarPeriodo: '',
      nomePessoaAdicionarPeriodo: '',
      idPessoaAdicionarPeriodo: '',
      idPessoaSelecionadaDiretamente: 0,
      idPessoaSelecionadaDiretamenteAcessoFull: 0,
      idPessoaSelecionadaDiretamenteBloqueioFull: 0,
      idPerfilAcessoFullSelecionado: 0,

      clonaConfiguracoes: false,
    }
  }

  switch (action.type) {
    case INICIA_AMBIENTE: {
      return {
        ...state,
        loader: true,
        clonaConfiguracoes: false
      }
    }
    case INICIO_AMBIENTE_SUCESSO: {

      const retorno = action.payload;

      return {
        ...state,
        loader: false,
        carregandoLista: false,
        listaLocaisCompleta: retorno.locais,
        listaDePerfis: preparaListaParaCombo(retorno.perfis, "id", "descricaoPerfil"),
        idLocalControladoFixado: 0,
        textoAmbienteAdicionar: '',
        listaDeLocaisControlados: preparaListaParaCombo(retorno.locais, "id", "descricaoLocalControlado"),
        listaDePessoasDoCliente: preparaListaParaCombo(retorno.usuariosDoCliente, "id", "nomeCPF"),
        listaDePessoasDoClienteCompleto: retorno.usuariosDoCliente,
        idPontoAcessoSelecionado: 0,
        alertMessage: '',
        showMessageSuccess: false,
        showMessageError: false,
      }
    }
    case INICIO_CENARIO_AMBIENTE_LOCAL: {
      const retorno = action.payload;

      return {
        ...state,
        idLocalControladoFixado: retorno.idLocal,
        loader: true,
      }
    }
    case ATUALIZA_AMBIENTE_PORTARIA: {
      const retorno = action.payload;
      var listaDeAmbientesProcessada = state.listaAmbientesDoLocal;

      function AtualizaArrayAmbientePortaria(listaDeAmbientes) {
        
        for (var indice in listaDeAmbientes) {
          var ambiente = listaDeAmbientes[indice];
  
          if(ambiente.subAmbientes !== undefined && ambiente.subAmbientes.length > 0) {
            AtualizaArrayAmbientePortaria(ambiente.subAmbientes);
          }

          if(ambiente.idTemporario !== undefined && ambiente.idTemporario === retorno.idAtualizacao) {
            ambiente.ehAmbientePortaria = retorno.valor;
          }

        }

      }

      AtualizaArrayAmbientePortaria(listaDeAmbientesProcessada);

      return {
        ...state,
        listaAmbientesDoLocal: listaDeAmbientesProcessada
      }
    } 
    case ATUALIZA_CONTROLE_GARAGEM: {
      const retorno = action.payload;
      var listaDeAmbientesProcessada = state.listaAmbientesDoLocal;

      function AtualizaArrayAmbientePortaria(listaDeAmbientes) {
        
        for (var indice in listaDeAmbientes) {
          var ambiente = listaDeAmbientes[indice];
  
          if(ambiente.subAmbientes !== undefined && ambiente.subAmbientes.length > 0) {
            AtualizaArrayAmbientePortaria(ambiente.subAmbientes);
          }

          if(ambiente.idTemporario !== undefined && ambiente.idTemporario === retorno.idTemporarioAmbiente) {
            ambiente.controlaGaragem = !ambiente.controlaGaragem ;
          }

        }

      }

      AtualizaArrayAmbientePortaria(listaDeAmbientesProcessada);

      return {
        ...state,
        listaAmbientesDoLocal: listaDeAmbientesProcessada
      }
    }
    
    case INICIO_CENARIO_AMBIENTE_LOCAL_SUCESSO: {
      const retorno = action.payload;
      var idsExpandidos = [];
      var ambiente = {};

      function RecolheIdsAmbienteSubAmbiente(ambientes) {

        for (var indice in ambientes) {
          ambiente = ambientes[indice];

          if (ambiente.subAmbientes.length > 0) {
            idsExpandidos.push(ambiente.idTemporario);
            RecolheIdsAmbienteSubAmbiente(ambiente.subAmbientes);
          }
          else {
            idsExpandidos.push(ambiente.idTemporario);
          }
        }

      }

      RecolheIdsAmbienteSubAmbiente(retorno.ambientes);

      return {
        ...state,
        loader: false,
        idLocalControladoFixado: retorno.idLocal,
        idPontoGestaoAcesso: '',
        nomeLocalControladoFixado: state.nomeLocalControladoFixado,
        listaAmbientesDoLocal: retorno.ambientes,
        contadorAmbiente: retorno.contadorAmbiente,
        contadorPontoAcesso: retorno.contadorPontoAcesso,
        listaDeEquipamentos: preparaListaParaCombo(retorno.equipamentos, "id", "nomeExibicao"),
        listaDeControladoras: preparaListaParaCombo(retorno.controladoras, "id", "nomeExibicao"),
        listaDeFormasComunicacao: preparaListaParaCombo(formascomunicacaoEquipamentoCliente, "id", "title"),
        listaDeTiposCheckin: preparaListaParaCombo(tiposCheckin, "id", "title"),
        listaDeTiposDePontoAcesso: preparaListaParaCombo(tiposPontoAcesso, "id", "title"),
        idPontoAcessoSelecionado: 0,
        listaIdsTreeviewExpandidos: idsExpandidos,
        textoPrimeiroAmbienteAdicionar: '',
        alertMessage: '',
        showMessageSuccess: false,
        showMessageError: false,
      }
    }
    case OBTER_ACESSO_LOCAL_CONTROLADO: {
      const retorno = action.payload;

      return {
        ...state,
        idLocalControladoFixado: retorno.idLocal,
        loader: true,
      }
    }
    case OBTER_ACESSO_LOCAL_CONTROLADO_SUCESSO: {
      const retorno = action.payload;
      var idsExpandidos = [];
      var ambiente = {};

      function RecolheIdsAmbienteSubAmbiente(ambientes) {

        for (var indice in ambientes) {
          ambiente = ambientes[indice];

          if (ambiente.subAmbientes.length > 0) {
            idsExpandidos.push(ambiente.idTemporario);
            RecolheIdsAmbienteSubAmbiente(ambiente.subAmbientes);
          }
          else {
            idsExpandidos.push(ambiente.idTemporario);
          }
        }

      }

      RecolheIdsAmbienteSubAmbiente(retorno.ambientes);
      return {
        ...state,
        loader: false,
        idPontoGestaoAcesso: '',
        idLocalControladoFixado: retorno.idLocal,
        nomeLocalControladoFixado: state.nomeLocalControladoFixado,
        listaAmbientesDoLocal: retorno.ambientes,
        contadorAmbiente: retorno.contadorAmbiente,
        contadorPontoAcesso: retorno.contadorPontoAcesso,
        idPontoAcessoSelecionado: 0,
        listaDePessoasDoLocal: preparaListaParaCombo(retorno.pessoasAptasLocal, "idPessoa", "nomeCPF"),
        listaDePessoasDoLocalCompleto: retorno.pessoasAptasLocal,
        listaIdsTreeviewExpandidos: idsExpandidos,
        perfis: retorno.perfis,
        exibeEstruturaCopiaAcessos: false
      }
    }
    case SALVA_AMBIENTES_SUCESSO: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload,
        showMessageSuccess: true,
        ////edicao: false,
        ////idLocalControladoFixado: state.idLocalControladoFixado
      }
    }
    case SALVA_AMBIENTES: {
      return {
        ...state,
        loader: true,
      }
    }
    case SALVA_GESTAO_ACESSO_SUCESSO: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload,
        showMessageSuccess: true,
        ////edicao: false,
        ////idLocalControladoFixado: state.idLocalControladoFixado
      }
    }
    case SALVA_GESTAO_ACESSO: {
      return {
        ...state,
        loader: true,
      }
    }
    case ADICIONA_PRIMEIRO_AMBIENTE_LOCAL: {
      const contador = state.contadorAmbiente + 1;
      const primeiroAmbienteAdd = {
        "idLocalControlado": state.idLocalControladoFixado,
        "ehAmbientePortaria": false,
        "descricaoAmbiente": state.textoPrimeiroAmbienteAdicionar,
        "ativo": true,
        "idAmbientePai": null,
        "idTemporario": contador,
        "id": 0,
        "subAmbientes": [],
        "pontosAcesso": []
      }

      return {
        ...state,
        contadorAmbiente: contador,
        listaAmbientesDoLocal: [...state.listaAmbientesDoLocal, primeiroAmbienteAdd],
      }
    }
    case CLIQUE_ADICIONA_AMBIENTE_LOCAL: {

      return {
        ...state,
        textoAmbienteAdicionar: '',
        textoSubAmbienteAdicionar: '',
        textoPontoAcessoAdicionar: '',
        novoAmbiente: true,
        idAmbienteAddPontoAcesso: null,
        idAmbienteAddSubAmbiente: null
      }
    }
    case CLIQUE_ADICIONA_PONTO_ACESSO: {

      const retorno = action.payload;

      return {
        ...state,
        idAmbienteAddPontoAcesso: retorno.idAmbiente,
        idAmbienteAddSubAmbiente: null,
        textoAmbienteAdicionar: '',
        novoAmbiente: false,
        textoSubAmbienteAdicionar: '',
        textoPontoAcessoAdicionar: '',
      }
    }
    case PREENCHE_INFORMACAO_PONTO: {

      var ambiente = {};
      var pontoAcesso = {};
      var listaProcessada = [];
      var caminhoAcumulado = '';

      const localizarPontoAcesso = (subAmbientes, idPontoAtualizar) => {
        var ambientes = subAmbientes.filter(x => x.descricaoAmbiente !== undefined);

        for (var indice in ambientes) {
          let objAmbiente = ambientes[indice];
          let possuiPontoAcesso = (objAmbiente.pontosAcesso && objAmbiente.pontosAcesso.length > 0);

          if (possuiPontoAcesso) {
            var pontosSelecionados = objAmbiente.pontosAcesso.filter(x => x.idTemporario === idPontoAtualizar);

            if (pontosSelecionados && pontosSelecionados.length > 0) {
              let objPonto = pontosSelecionados[0];

              if (objPonto.idPontoClonado === null) {

                state.idEquipamento = objPonto.idEquipamento;
                state.ipControladora = objPonto.ipControladora;
                state.idEquipamentoControladora = objPonto.idEquipamentoControladora;
                state.caminhoComunicServer = objPonto.caminhoComunicServer === null || objPonto.caminhoComunicServer === undefined ? '' : objPonto.caminhoComunicServer;
                state.formaComunicacao = objPonto.formaComunicacao;
                state.ipEquipamento = objPonto.ipEquipamento === null || objPonto.ipEquipamento === undefined ? '' : objPonto.ipEquipamento;
                state.tipoPontoAcesso = objPonto.tipoPontoAcesso;
                state.tipoPontoAcessoStr = objPonto.tipoPontoAcesso;
                state.tipoCheckin = objPonto.tipoCheckin;
                state.ehUzerPassPremium = objPonto.ehUzerPassPremium;
                state.duploCheck = objPonto.duploCheck;
                state.vinculoControladora = objPonto.vinculoControladora;
                state.possuiTerminal = objPonto.possuiTerminal;
              }

              state.nomePontoAcesso = objPonto.descricaoPontoAcesso;
              state.idPontoClonado = objPonto.idPontoClonado;
              state.clonaConfiguracoes = objPonto.idPontoClonado !== null;
              break;
            }
          }
          let possuiSubAmbiente = (objAmbiente.subAmbientes && objAmbiente.subAmbientes.length > 0);



          if (possuiSubAmbiente) {
            localizarPontoAcesso(objAmbiente.subAmbientes, idPontoAtualizar);
          }
        }
      };

      function MontaComboComPontosAcesso(ambientes, prefixo) {

        for (var indice in ambientes) {
          ambiente = ambientes[indice];

          if (prefixo !== undefined && prefixo !== null && prefixo !== '') {
            if (caminhoAcumulado === '') {
              caminhoAcumulado = prefixo;
            }
            else {
              caminhoAcumulado = caminhoAcumulado + ' - ' + prefixo;
            }
          }

          if (ambiente.subAmbientes !== undefined && ambiente.subAmbientes.length > 0) {

            if (ambiente.pontosAcesso.length > 0) {

              for (var indice in ambiente.pontosAcesso) {
                pontoAcesso = ambiente.pontosAcesso[indice];

                if (pontoAcesso.tipoPontoAcessoStr !== undefined && pontoAcesso.tipoPontoAcessoStr !== null && pontoAcesso.tipoPontoAcessoStr !== '') {
                  listaProcessada.push({ id: pontoAcesso.id, idTemporario: pontoAcesso.idTemporario, nome: caminhoAcumulado === '' ? pontoAcesso.descricaoAmbiente + ' - ' + pontoAcesso.descricaoPontoAcesso : caminhoAcumulado + ' - ' + pontoAcesso.descricaoAmbiente + ' - ' + pontoAcesso.descricaoPontoAcesso });
                }


              }

            }

            MontaComboComPontosAcesso(ambiente.subAmbientes, ambiente.descricaoAmbiente);

            caminhoAcumulado = caminhoAcumulado.replace(caminhoAcumulado.substring(caminhoAcumulado.lastIndexOf("-")), '');
          }
          else {

            if (ambiente.pontosAcesso !== undefined && ambiente.pontosAcesso.length > 0) {

              for (var indice in ambiente.pontosAcesso) {
                pontoAcesso = ambiente.pontosAcesso[indice];

                if (pontoAcesso.tipoPontoAcessoStr !== undefined && pontoAcesso.tipoPontoAcessoStr !== null && pontoAcesso.tipoPontoAcessoStr !== '') {
                  listaProcessada.push({ id: pontoAcesso.id, idTemporario: pontoAcesso.idTemporario, nome: caminhoAcumulado === '' ? pontoAcesso.descricaoAmbiente + ' - ' + pontoAcesso.descricaoPontoAcesso : caminhoAcumulado + ' - ' + pontoAcesso.descricaoAmbiente + ' - ' + pontoAcesso.descricaoPontoAcesso });
                }
              }

              var teste = caminhoAcumulado.substring(caminhoAcumulado.lastIndexOf("-"));
              caminhoAcumulado = caminhoAcumulado.replace(teste, '');

            }
          }
        }

      }

      const localizarDadosPontoAcessoClonado = (subAmbientes, idPontoAtualizar) => {
        var ambientes = subAmbientes.filter(x => x.descricaoAmbiente !== undefined);

        for (var indice in ambientes) {
          let objAmbiente = ambientes[indice];
          let possuiPontoAcesso = (objAmbiente.pontosAcesso && objAmbiente.pontosAcesso.length > 0);

          if (possuiPontoAcesso) {
            var pontosSelecionados = objAmbiente.pontosAcesso.filter(x => x.idTemporario === idPontoAtualizar);

            if (pontosSelecionados && pontosSelecionados.length > 0) {
              let objPonto = pontosSelecionados[0];

              state.idEquipamento = objPonto.idEquipamento;
              state.idEquipamentoControladora = objPonto.idEquipamentoControladora;
              state.ipControladora = objPonto.ipControladora;
              state.caminhoComunicServer = objPonto.caminhoComunicServer === null || objPonto.caminhoComunicServer === undefined ? '' : objPonto.caminhoComunicServer;
              state.formaComunicacao = objPonto.formaComunicacao;
              state.ipEquipamento = objPonto.ipEquipamento === null || objPonto.ipEquipamento === undefined ? '' : objPonto.ipEquipamento;
              state.tipoPontoAcesso = objPonto.tipoPontoAcesso;
              state.tipoPontoAcessoStr = objPonto.tipoPontoAcesso;
              state.tipoCheckin = objPonto.tipoCheckin;
              state.ehUzerPassPremium = objPonto.ehUzerPassPremium;
              state.idPontoClonado = objPonto.id;
              break;
            }
          }
          let possuiSubAmbiente = (objAmbiente.subAmbientes && objAmbiente.subAmbientes.length > 0);

          if (possuiSubAmbiente) {
            localizarDadosPontoAcessoClonado(objAmbiente.subAmbientes, idPontoAtualizar);
          }
        }
      };

      let idPonto = parseInt(action.payload)
      localizarPontoAcesso(state.listaAmbientesDoLocal, idPonto);

      MontaComboComPontosAcesso(state.listaAmbientesDoLocal, '');

      var idTemporarioClonado = 0;

      if (state.idPontoClonado !== null && state.idPontoClonado !== undefined) {
        idTemporarioClonado = listaProcessada.filter(x => x.id === state.idPontoClonado)[0].idTemporario;
        localizarDadosPontoAcessoClonado(state.listaAmbientesDoLocal, idTemporarioClonado);
      }

      return {
        ...state,
        listaDePontosParaClonagemTecnicaCompleta: listaProcessada,
        listaDePontosParaClonagemTecnica: preparaListaParaCombo(listaProcessada.filter(x => x.idTemporario !== idPonto), "idTemporario", "nome"),
        idPontoAcessoSelecionado: idPonto,
        idPontoClonar: idTemporarioClonado
      }
    }
    case REMOVE_PONTO_ACESSO_LOCAL: {
      const removerPontoAcesso = (subAmbientes, idPontoRemover) => {
        var ambientes = subAmbientes.filter(x => x.descricaoAmbiente !== undefined);

        for (var indice in ambientes) {
          let objAmbiente = ambientes[indice];
          let possuiPontoAcesso = (objAmbiente.pontosAcesso && objAmbiente.pontosAcesso.length > 0);

          if (possuiPontoAcesso) {
            var ponto = objAmbiente.pontosAcesso.filter(x => x.idTemporario === idPontoRemover);

            if (ponto && ponto.length > 0) {
              objAmbiente.pontosAcesso = objAmbiente.pontosAcesso.filter(x => x.idTemporario !== idPontoRemover);
              break;
            }
          }
          let possuiSubAmbiente = (objAmbiente.subAmbientes && objAmbiente.subAmbientes.length > 0);

          if (possuiSubAmbiente) {
            objAmbiente.subAmbientes = removerPontoAcesso(objAmbiente.subAmbientes, idPontoRemover);
          }
        }

        return ambientes;
      };

      let idPontoRemover = parseInt(action.payload)
      let arrayAtualizado = removerPontoAcesso(state.listaAmbientesDoLocal, idPontoRemover);

      return {
        ...state,
        idPontoAcessoSelecionado: 0,
        idEquipamento: 0,
        ipControladora: 0,
        idEquipamentoControladora: 0,
        caminhoComunicServer: '',
        formaComunicacao: 0,
        ipEquipamento: '',
        nomePontoAcesso: '',
        tipoPontoAcesso: 0,
        tipoCheckin: 0,
        ehUzerPassPremium: false,
        listaAmbientesDoLocal: [...state.listaAmbientesDoLocal, arrayAtualizado],
      }
    }
    case REMOVE_AMBIENTE: {
      const removerAmbiente = (subAmbientes, idAmbienteRemover) => {
        var ambientesFiltrados = subAmbientes.filter(x => x.idTemporario !== undefined);
        var ambiente = ambientesFiltrados.filter(x => x.idTemporario === idAmbienteRemover);

        if (ambiente && ambiente.length > 0) {
          subAmbientes = ambientesFiltrados.filter(x => x.idTemporario !== idAmbienteRemover);
        }
        else {
          for (var indice in ambientesFiltrados) {
            let objAmbiente = ambientesFiltrados[indice];
            let possuiSubAmbiente = (objAmbiente.subAmbientes && objAmbiente.subAmbientes.length > 0);
            if (possuiSubAmbiente) {
              objAmbiente.subAmbientes = removerAmbiente(objAmbiente.subAmbientes, idAmbienteRemover);
            }
          }
        }

        return subAmbientes;
      };

      const idAmbienteRemover = parseInt(action.payload);
      let arrayAtualizado = removerAmbiente(state.listaAmbientesDoLocal, idAmbienteRemover);

      return {
        ...state,
        idPontoAcessoSelecionado: 0,
        idEquipamento: 0,
        ipControladora: 0,
        idEquipamentoControladora: 0,
        caminhoComunicServer: '',
        formaComunicacao: 0,
        ipEquipamento: '',
        nomePontoAcesso: '',
        tipoPontoAcesso: 0,
        tipoCheckin: 0,
        ehUzerPassPremium: false,
        listaAmbientesDoLocal: arrayAtualizado ////[...state.listaAmbientesDoLocal, arrayAtualizado],
      }
    }
    case ATUALIZA_INFORMACAO_PONTO: {

      const atualizarPontoAcesso = (subAmbientes, idPontoAtualizar) => {
        var ambientes = subAmbientes.filter(x => x.descricaoAmbiente !== undefined);

        for (var indice in ambientes) {
          let objAmbiente = ambientes[indice];
          let possuiPontoAcesso = (objAmbiente.pontosAcesso && objAmbiente.pontosAcesso.length > 0);

          if (possuiPontoAcesso) {
            var pontosSelecionados = objAmbiente.pontosAcesso.filter(x => x.idTemporario === idPontoAtualizar);

            if (pontosSelecionados && pontosSelecionados.length > 0) {
              let objPonto = pontosSelecionados[0];

              objPonto.idPontoClonado = state.idPontoClonado;
              objPonto.descricaoPontoAcesso = state.nomePontoAcesso;
              objPonto.ipControladora = state.ipControladora;
              objPonto.idEquipamento = state.idEquipamento;
              objPonto.idEquipamentoControladora = state.idEquipamentoControladora;
              objPonto.caminhoComunicServer = state.caminhoComunicServer;
              objPonto.formaComunicacao = state.formaComunicacao;
              objPonto.ipEquipamento = state.ipEquipamento;
              objPonto.tipoPontoAcesso = state.tipoPontoAcesso;
              objPonto.tipoPontoAcessoStr = state.tipoPontoAcesso;
              objPonto.tipoCheckin = state.tipoCheckin;
              objPonto.duploCheck = state.duploCheck;
              objPonto.possuiTerminal = state.possuiTerminal;
              objPonto.vinculoControladora = state.vinculoControladora;
              objPonto.ehUzerPassPremium = state.ehUzerPassPremium;
              break;
            }
          }
          let possuiSubAmbiente = (objAmbiente.subAmbientes && objAmbiente.subAmbientes.length > 0);

          if (possuiSubAmbiente) {
            objAmbiente.subAmbientes = atualizarPontoAcesso(objAmbiente.subAmbientes, idPontoAtualizar);
          }
        }

        return ambientes;
      };

      let idPonto = parseInt(state.idPontoAcessoSelecionado)
      let arrayAtualizado = atualizarPontoAcesso(state.listaAmbientesDoLocal, idPonto);

      return {
        ...state,
        listaAmbientesDoLocal: [...state.listaAmbientesDoLocal, arrayAtualizado],
        idPontoAcessoSelecionado: 0,
        idEquipamento: 0,
        idEquipamentoControladora: 0,
        ipControladora: 0,
        caminhoComunicServer: '',
        formaComunicacao: 0,
        ipEquipamento: '',
        nomePontoAcesso: '',
        tipoPontoAcesso: 0,
        tipoCheckin: 0,
        ehUzerPassPremium: false
      }
    }
    case CANCELA_INFORMACAO_PONTO: {

      return {
        ...state,
        idPontoAcessoSelecionado: 0,
        idEquipamento: 0,
        idEquipamentoControladora: 0,
        ipControladora: 0,
        caminhoComunicServer: '',
        formaComunicacao: 0,
        ipEquipamento: '',
        nomePontoAcesso: '',
        tipoPontoAcesso: 0,
        tipoCheckin: 0,
        ehUzerPassPremium: false
      }
    }
    case CLIQUE_ADICIONA_SUB_AMBIENTE: {

      const retorno = action.payload;

      return {
        ...state,
        idAmbienteAddSubAmbiente: retorno.idAmbiente,
        idAmbienteAddPontoAcesso: null,
        textoAmbienteAdicionar: '',
        novoAmbiente: false,
        textoSubAmbienteAdicionar: '',
        textoPontoAcessoAdicionar: '',
      }

    }
    case ADICIONA_AMBIENTE_LOCAL: {

      const contador = state.contadorAmbiente + 1;

      const ambienteAdd = {
        "idLocalControlado": state.idLocalControladoFixado,
        "ehAmbientePortaria": false,
        "descricaoAmbiente": state.textoAmbienteAdicionar,
        "ativo": true,
        "idAmbientePai": null,
        "idTemporario": contador,
        "id": 0,
        "subAmbientes": [],
        "pontosAcesso": []
      }

      return {
        ...state,
        listaAmbientesDoLocal: [...state.listaAmbientesDoLocal, ambienteAdd],
        novoAmbiente: false,
        contadorAmbiente: contador,
      }
    }
    case ADICIONA_PONTO_ACESSO: {

      const acessarPontoAcessoInterno = (ambientes, pontoAcessoAdd) => {
        for (var indice in ambientes) {
          let objAmbiente = ambientes[indice];

          if (objAmbiente.idTemporario === state.idAmbienteAddPontoAcesso) {
            objAmbiente.pontosAcesso = [...objAmbiente.pontosAcesso, pontoAcessoAdd];
            return ambientes;
          }
          else {
            let possuiSubAmbiente = (objAmbiente.subAmbientes && objAmbiente.subAmbientes.length > 0);
            if (possuiSubAmbiente) {
              objAmbiente.subAmbientes = acessarPontoAcessoInterno(objAmbiente.subAmbientes, pontoAcessoAdd);
              ////return ambientes;
            }
            //  else{
            //   return ambientes;
            //  }
          }
        }
        return ambientes;
      };

      let contador = state.contadorPontoAcesso + 1;
      const pontoAcessoAdd = {
        "idAmbiente": state.idAmbienteAddPontoAcesso,
        "descricaoPontoAcesso": state.textoPontoAcessoAdicionar,
        "id": 0,
        "idTemporario": contador,
        "idPontoClonado": null
      }

      let arrayAtualizado = [];
      var ambientes = state.listaAmbientesDoLocal.filter(x => x.descricaoAmbiente !== undefined);

      for (var indice in ambientes) {
        let objAmbiente = ambientes[indice];

        if (objAmbiente.idTemporario === state.idAmbienteAddPontoAcesso) {
          objAmbiente.pontosAcesso = [...objAmbiente.pontosAcesso, pontoAcessoAdd];
          arrayAtualizado = [...arrayAtualizado, ambientes];
          break;
        }
        else {
          let possuiSubAmbiente = (objAmbiente.subAmbientes && objAmbiente.subAmbientes.length > 0);
          if (possuiSubAmbiente) {
            objAmbiente.subAmbientes = acessarPontoAcessoInterno(objAmbiente.subAmbientes, pontoAcessoAdd);
            arrayAtualizado = ambientes;
          }
        }
      }

      return {
        ...state,
        listaAmbientesDoLocal: [...state.listaAmbientesDoLocal, arrayAtualizado],
        idAmbienteAddPontoAcesso: null,
        textoPontoAcessoAdicionar: '',
        novoAmbiente: false,
        contadorPontoAcesso: contador,
      }
    }
    case ADICIONA_SUB_AMBIENTE: {

      const acessarSubAmbienteInterno = (ambientes, subAmbienteAdd) => {
        for (var indice in ambientes) {
          let objAmbiente = ambientes[indice];

          if (objAmbiente.idTemporario === state.idAmbienteAddSubAmbiente) {
            objAmbiente.subAmbientes = [...objAmbiente.subAmbientes, subAmbienteAdd];
            return ambientes;
          }
          else {
            let possuiSubAmbiente = (objAmbiente.subAmbientes && objAmbiente.subAmbientes.length > 0);
            if (possuiSubAmbiente) {
              objAmbiente.subAmbientes = acessarSubAmbienteInterno(objAmbiente.subAmbientes, subAmbienteAdd);
              ////return ambientes;
            }
            //  else{
            //   return ambientes;
            //  }
          }
        }
        return ambientes;

      };

      const contador = state.contadorAmbiente + 1;
      const subAmbienteAdd = {
        "idTemporario": contador,
        "ehAmbientePortaria": false,
        "localControlado": null,
        "idLocalControlado": state.idLocalControladoFixado,
        "descricaoAmbiente": state.textoSubAmbienteAdicionar,
        "idAmbientePai": state.idAmbienteAddSubAmbiente,
        "ativo": true,
        "subAmbientes": [],
        "pontosAcesso": [],
        "id": 0
      }

      let arrayAtualizado = [];
      let ambientes = state.listaAmbientesDoLocal.filter(x => x.descricaoAmbiente !== undefined);

      for (var indice in ambientes) {
        let objAmbiente = ambientes[indice];

        if (objAmbiente.idTemporario === state.idAmbienteAddSubAmbiente) {
          objAmbiente.subAmbientes = [...objAmbiente.subAmbientes, subAmbienteAdd];
          arrayAtualizado = [...arrayAtualizado, ambientes];
          break;
        }
        else {
          let possuiSubAmbiente = (objAmbiente.subAmbientes && objAmbiente.subAmbientes.length > 0);
          if (possuiSubAmbiente) {
            objAmbiente.subAmbientes = acessarSubAmbienteInterno(objAmbiente.subAmbientes, subAmbienteAdd);
            arrayAtualizado = ambientes;
          }
        }
      }

      return {
        ...state,
        listaAmbientesDoLocal: [...state.listaAmbientesDoLocal, arrayAtualizado],
        idAmbienteAddSubAmbiente: null,
        textoSubAmbienteAdicionar: '',
        novoAmbiente: false,
        contadorAmbiente: contador,
        listaIdsTreeviewExpandidos: []
      }
    }
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload
      }
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessageSuccess: false,
        showMessageError: false,
        loader: false
      }
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      }
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      }
    }
    case ON_SHOW_ERROR_MESSAGE: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload,
        showMessageError: true,
        edicao: false
      }
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessageError: true,
        loader: false
      }
    }
    case ALTERACAO_CAMPO: {
      return {
        ...state,
        [action.payload.target.name]: action.payload.target.value,
      }
    }
    case ALTERACAO_COMBO: {

      var campo = action.payload.nomeCombo[0];

      const localizarDadosPontoAcessoClonado = (subAmbientes, idPontoAtualizar) => {
        var ambientes = subAmbientes.filter(x => x.descricaoAmbiente !== undefined);

        for (var indice in ambientes) {
          let objAmbiente = ambientes[indice];
          let possuiPontoAcesso = (objAmbiente.pontosAcesso && objAmbiente.pontosAcesso.length > 0);

          if (possuiPontoAcesso) {
            var pontosSelecionados = objAmbiente.pontosAcesso.filter(x => x.idTemporario === idPontoAtualizar);

            if (pontosSelecionados && pontosSelecionados.length > 0) {
              let objPonto = pontosSelecionados[0];

              state.idEquipamento = objPonto.idEquipamento;
              state.idEquipamentoControladora = objPonto.idEquipamentoControladora;
              state.ipControladora = objPonto.ipControladora;
              state.caminhoComunicServer = objPonto.caminhoComunicServer === null || objPonto.caminhoComunicServer === undefined ? '' : objPonto.caminhoComunicServer;
              state.formaComunicacao = objPonto.formaComunicacao;
              state.ipEquipamento = objPonto.ipEquipamento === null || objPonto.ipEquipamento === undefined ? '' : objPonto.ipEquipamento;
              state.tipoPontoAcesso = objPonto.tipoPontoAcesso;
              state.tipoPontoAcessoStr = objPonto.tipoPontoAcesso;
              state.tipoCheckin = objPonto.tipoCheckin;
              state.ehUzerPassPremium = objPonto.ehUzerPassPremium;
              state.idPontoClonado = objPonto.id;
              break;
            }
          }
          let possuiSubAmbiente = (objAmbiente.subAmbientes && objAmbiente.subAmbientes.length > 0);

          if (possuiSubAmbiente) {
            localizarDadosPontoAcessoClonado(objAmbiente.subAmbientes, idPontoAtualizar);
          }
        }
      };



      if (campo === "idPontoClonar") {
        localizarDadosPontoAcessoClonado(state.listaAmbientesDoLocal, action.payload.valor);
      }

      return {
        ...state,
        [action.payload.nomeCombo]: action.payload.valor,
      }
    }
    case ALTERACAO_DATEPICKER: {
      return {
        ...state,
        [action.payload.name]: action.payload.date,
      }
    }
    case ALTERACAO_CHECK: {

      var campo = action.payload.nomeCheck[0];
      var idPontoReplicacao = 0;
      var arrayAtualizado = [];
      var ambiente = {};
      var novoArray = [];
      var pontoAcessoOrigem = {};
      var pontoAcessoDestino = {};

      function LocalizaPontoDeAcessoOrigem(ambientes, idPontoOrigem) {

        for (var indice in ambientes) {
          ambiente = ambientes[indice];

          if (ambiente.pontosAcesso && ambiente.pontosAcesso.length > 0) {
            if (ambiente.pontosAcesso.filter(x => x.idTemporario === idPontoOrigem).length > 0) {
              pontoAcessoOrigem = ambiente.pontosAcesso.filter(x => x.idTemporario === idPontoOrigem)[0];
            }
            else {
              if (ambiente.subAmbientes.length > 0) {
                LocalizaPontoDeAcessoOrigem(ambiente.subAmbientes, idPontoOrigem);
              }
            }
          }

        }

      }

      function LocalizaAtribuiPontoDeAcessoDestino(ambientes, idPontoDestino) {

        for (var indice in ambientes) {
          ambiente = ambientes[indice];

          if (ambiente.pontosAcesso && ambiente.pontosAcesso.length > 0) {
            if (ambiente.pontosAcesso.filter(x => x.idTemporario === idPontoDestino).length > 0) {
              pontoAcessoDestino = ambiente.pontosAcesso.filter(x => x.idTemporario === idPontoDestino)[0];

              if (campo.lastIndexOf('pontoReplica_') !== -1) {
                if (action.payload.valor) {

                  pontoAcessoDestino.acessosPessoa = pontoAcessoOrigem.acessosPessoa;
                  pontoAcessoDestino.acessosPerfil = pontoAcessoOrigem.acessosPerfil;
                  pontoAcessoDestino.acessosGenero = pontoAcessoOrigem.acessosGenero;
                  pontoAcessoDestino.acessosIdade = pontoAcessoOrigem.acessosIdade;
                  pontoAcessoDestino.acessosPeriodo = pontoAcessoOrigem.acessosPeriodo;
                  pontoAcessoDestino.acessosPessoaFull = pontoAcessoOrigem.acessosPessoaFull;
                  pontoAcessoDestino.acessosPessoaBloqueio = pontoAcessoOrigem.acessosPessoaBloqueio;

                }
                else {
                  pontoAcessoDestino.acessosPessoa = [];
                  pontoAcessoDestino.acessosPerfil = [];
                  pontoAcessoDestino.acessosGenero = [];
                  pontoAcessoDestino.acessosIdade = [];
                  pontoAcessoDestino.acessosPeriodo = [];
                  pontoAcessoDestino.acessosPessoaFull = [];
                  pontoAcessoDestino.acessosPessoaBloqueio = [];
                }
              }

              if (campo === "pontoHerancaMarcado") {
                pontoAcessoDestino.acessosPessoa = pontoAcessoOrigem.acessosPessoa;
                pontoAcessoDestino.acessosPerfil = pontoAcessoOrigem.acessosPerfil;
                pontoAcessoDestino.acessosGenero = pontoAcessoOrigem.acessosGenero;
                pontoAcessoDestino.acessosIdade = pontoAcessoOrigem.acessosIdade;
                pontoAcessoDestino.acessosPeriodo = pontoAcessoOrigem.acessosPeriodo;
                pontoAcessoDestino.acessosPessoaFull = pontoAcessoOrigem.acessosPessoaFull;
                pontoAcessoDestino.acessosPessoaBloqueio = pontoAcessoOrigem.acessosPessoaBloqueio;
              }

            }
            else {
              if (ambiente.subAmbientes.length > 0) {
                LocalizaAtribuiPontoDeAcessoDestino(ambiente.subAmbientes, idPontoDestino);
              }
            }
          }

        }

      }

      if (campo.lastIndexOf('pontoReplica_') !== -1) {
        idPontoReplicacao = parseInt(campo.replace('pontoReplica_', ''));

        for (var indice in state.listaDePontosReplicacao) {
          var ponto = state.listaDePontosReplicacao[indice];

          if (ponto.id === idPontoReplicacao) {
            ponto.marcado = action.payload.valor;
          }

          arrayAtualizado.push(ponto);
        }

        LocalizaPontoDeAcessoOrigem(state.listaAmbientesDoLocal, state.idPontoAcessoSelecionado)

        novoArray = state.listaAmbientesDoLocal;
        LocalizaAtribuiPontoDeAcessoDestino(novoArray, idPontoReplicacao);
      }

      if (campo === "pontoHerancaMarcado") {
        LocalizaPontoDeAcessoOrigem(state.listaAmbientesDoLocal, parseInt(action.payload.valor))

        novoArray = state.listaAmbientesDoLocal;
        LocalizaAtribuiPontoDeAcessoDestino(novoArray, state.idPontoAcessoSelecionado);
      }

      var teste = pontoAcessoOrigem;
      var teste2 = novoArray;

      return {
        ...state,
        idPontoClonar: campo === "clonaConfiguracoes" && action.payload.valor === false ? 0 : state.idPontoClonar,
        idEquipamento: campo === "clonaConfiguracoes" && action.payload.valor === false ? 0 : state.idEquipamento,
        caminhoComunicServer: campo === "clonaConfiguracoes" && action.payload.valor === false ? '' : state.caminhoComunicServer,
        formaComunicacao: campo === "clonaConfiguracoes" && action.payload.valor === false ? 0 : state.formaComunicacao,
        ipEquipamento: campo === "clonaConfiguracoes" && action.payload.valor === false ? '' : state.ipEquipamento,
        tipoPontoAcesso: campo === "clonaConfiguracoes" && action.payload.valor === false ? 0 : state.tipoPontoAcesso,
        tipoCheckin: campo === "clonaConfiguracoes" && action.payload.valor === false ? 0 : state.tipoCheckin,
        ehUzerPassPremium: campo === "clonaConfiguracoes" && action.payload.valor === false ? false : state.ehUzerPassPremium,
        idPontoClonado: campo === "clonaConfiguracoes" && action.payload.valor === false ? 0 : state.idPontoClonado,
        listaDePontosReplicacao: campo.lastIndexOf('pontoReplica_') !== -1 ? arrayAtualizado : state.listaDePontosReplicacao,
        listaAmbientesDoLocal: campo.lastIndexOf('pontoReplica_') !== -1 || campo === 'pontoHerancaMarcado' ? novoArray : state.listaAmbientesDoLocal,
        acessosPessoa: campo === 'pontoHerancaMarcado' ? pontoAcessoDestino.acessosPessoa : state.acessosPessoa,
        acessosPerfil: campo === 'pontoHerancaMarcado' ? pontoAcessoDestino.acessosPerfil : state.acessosPerfil,
        acessosGenero: campo === 'pontoHerancaMarcado' ? pontoAcessoDestino.acessosGenero : state.acessosGenero,
        acessosIdade: campo === 'pontoHerancaMarcado' ? pontoAcessoDestino.acessosIdade : state.acessosIdade,
        acessosPeriodo: campo === 'pontoHerancaMarcado' ? pontoAcessoDestino.acessosPeriodo : state.acessosPeriodo,
        acessosPessoaFull: campo === 'pontoHerancaMarcado' ? pontoAcessoDestino.acessosPessoaFull : state.acessosPessoaFull,
        acessosPessoaBloqueio: campo === 'pontoHerancaMarcado' ? pontoAcessoDestino.acessosPessoaBloqueio : state.acessosPessoaBloqueio,
        [action.payload.nomeCheck]: action.payload.valor,
      }
    }
    case ALTERACAO_CAMPO_MASCARA_CPF: {
      return {
        ...state,
        [action.payload.target.name]: cpfMask(action.payload.target.value)
      }
    }
    case ALTERACAO_CAMPO_MASCARA_CNPJ: {
      return {
        ...state,
        [action.payload.target.name]: cnpjMask(action.payload.target.value)
      }
    }
    case ALTERACAO_CAMPO_MASCARA_TELEFONE: {
      return {
        ...state,
        [action.payload.target.name]: telefoneMask(action.payload.target.value)
      }
    }
    case ALTERACAO_CAMPO_MASCARA_IP: {
      return {
        ...state,
        [action.payload.target.name]: ipMask(action.payload.target.value)
      }
    }
    case ALTERACAO_CAMPO_MASCARA_CEP: {
      return {
        ...state,
        [action.payload.target.name]: cepMask(action.payload.target.value)
      }
    }
    case ALTERACAO_CAMPO_MASCARA_DATE: {
      return {
        ...state,
        [action.payload.target.name]: dateMask(action.payload.target.value),

      }
    }
    /*#############################################################################################*/
    /* ---------------------------------- Reducers gestão de acesso -------------------------------*/
    case CONSULTA_CPF_ACESSO: {
      return {
        ...state,
        loader: true,
      }
    }
    case CONSULTA_CPF_ACESSO_SUCESSO: {
      var retorno = action.payload;

      return {
        ...state,
        loader: false,
        idPessoaAdicionar: retorno.idPessoa,
        nomePessoaAdicionar: retorno.nomePessoa
      }
    }
    case CONSULTA_CPF_ACESSO_FULL: {
      return {
        ...state,
        loader: true,
      }
    }
    case CONSULTA_CPF_ACESSO_FULL_SUCESSO: {
      var retorno = action.payload;

      return {
        ...state,
        loader: false,
        nomePessoaAdicionarFull: retorno.nomePessoa,
        idPessoaAdicionarFull: retorno.idPessoa,
      }
    }
    case CONSULTA_CPF_ACESSO_BLOQUEIO: {
      return {
        ...state,
        loader: true,
      }
    }
    case CONSULTA_CPF_ACESSO_BLOQUEIO_SUCESSO: {
      var retorno = action.payload;

      return {
        ...state,
        loader: false,
        nomePessoaAdicionarBloqueio: retorno.nomePessoa,
        idPessoaAdicionarBloqueio: retorno.idPessoa,
      }
    }
    case CONSULTA_CPF_PERIODO: {
      return {
        ...state,
        loader: true,
      }
    }
    case CONSULTA_CPF_PERIODO_SUCESSO: {
      var retorno = action.payload;

      return {
        ...state,
        loader: false,
        nomePessoaAdicionarPeriodo: retorno.nomePessoa,
        idPessoaAdicionarPeriodo: retorno.idPessoa,
      }
    }
    case OBTER_INFORMACAO_ACESSO_PONTO: {
      const localizarPontoAcesso = (subAmbientes, idPontoAtualizar) => {
        var ambientes = subAmbientes.filter(x => x.descricaoAmbiente !== undefined);

        for (var indice in ambientes) {
          let objAmbiente = ambientes[indice];
          let possuiPontoAcesso = (objAmbiente.pontosAcesso && objAmbiente.pontosAcesso.length > 0);

          if (possuiPontoAcesso) {
            var pontosSelecionados = objAmbiente.pontosAcesso.filter(x => x.idTemporario === idPontoAtualizar);

            if (pontosSelecionados && pontosSelecionados.length > 0) {
              let objPonto = pontosSelecionados[0];

              state.nomePontoGestaoAcesso = objPonto.descricaoPontoAcesso;
              state.acessosPessoa = objPonto.acessosPessoa === null ? [] : objPonto.acessosPessoa;
              state.acessosGenero = objPonto.acessosGenero === null ? [] : objPonto.acessosGenero;
              state.acessosPerfil = objPonto.acessosPerfil === null ? [] : objPonto.acessosPerfil;
              state.acessosIdade = objPonto.acessosIdade === null ? [] : objPonto.acessosIdade;
              state.acessosPessoaFull = objPonto.acessosPessoaFull === null ? [] : objPonto.acessosPessoaFull;
              state.acessosPessoaBloqueio = objPonto.acessosPessoaBloqueio === null ? [] : objPonto.acessosPessoaBloqueio;
              state.acessosPeriodo = objPonto.acessosPeriodo === null ? [] : objPonto.acessosPeriodo;
              break;
            }
          }
          let possuiSubAmbiente = (objAmbiente.subAmbientes && objAmbiente.subAmbientes.length > 0);

          if (possuiSubAmbiente) {
            localizarPontoAcesso(objAmbiente.subAmbientes, idPontoAtualizar);
          }
        }
      };

      let idPonto = parseInt(action.payload)
      localizarPontoAcesso(state.listaAmbientesDoLocal, idPonto);


      return {
        ...state,
        idPontoGestaoAcesso: idPonto,
        numeroCpfAdicionar: '',
        nomePessoaAdicionar: '',
        idPessoaAdicionar: '',
        idPerfilSelecionado: '',
        idPerfilGeneroSelecionado: '',
        generoSelecionado: 'FEMININO',
        idPerfilIdadeSelecionado: '',
        idadeMinima: '',
        idadeMaxima: '',
        numeroCpfAdicionarFull: '',
        nomePessoaAdicionarFull: '',
        idPessoaAdicionarFull: '',
        numeroCpfAdicionarBloqueio: '',
        nomePessoaAdicionarBloqueio: '',
        idPessoaAdicionarBloqueio: '',
        idPerfilPeriodoSelecionado: '',
        todosDiasSelecionado: true,
        segundaAtivo: true,
        tercaAtivo: true,
        quartaAtivo: true,
        quintaAtivo: true,
        sextaAtivo: true,
        sabadoAtivo: true,
        domingoAtivo: true,
        emQualquerHorario: true,
        horarioInicio: '',
        horarioFim: '',
        horarioInicioSegundo: '',
        horarioFimSegundo: '',
        horarioInicioTerceiro: '',
        horarioFimTerceiro: '',
        inicioFimProgramado: false,
        dataInicioControle: null,
        dataFimControle: null,
        idTemporarioPeriodo: 0,
        idPeriodoSelecionado: 0,
        minutoInicio: '',
        minutoFim: '',
        minutoInicioSegundo: '',
        minutoFimSegundo: '',
        minutoInicioTerceiro: '',
        minutoFimTerceiro: '',
        tipoAcessoPeriodoSelecionado: "PERFIL",
        numeroCpfAdicionarPeriodo: '',
        nomePessoaAdicionarPeriodo: '',
        idPessoaAdicionarPeriodo: '',
        idPontoAcessoSelecionado: idPonto,
        horarios: state.horariosNovo,
      }
    }
    case REMOVE_PESSOA_LOCAL: {
      let idPessoaRemover = parseInt(action.payload);
      let arrayAtualizado = state.acessosPessoa.filter(x => x.idTemporario !== idPessoaRemover);

      let contador = 0;
      arrayAtualizado.map(n => {
        contador = contador + 1;
        n.idTemporario = contador;
      });

      state.acessosPessoa = arrayAtualizado;
      return atualizarGestaoAcessoLocal();
    }
    case ADICIONA_PESSOA_LOCAL_SUCESSO: {

      var pessoaSelecionada = state.listaDePessoasDoClienteCompleto.filter(x => x.id === state.idPessoaSelecionadaDiretamente);
      var statusPessoa = state.listaDePessoasDoLocalCompleto.filter(x => x.idPessoa === state.idPessoaSelecionadaDiretamente)[0].statusPessoaLocal;

      const pessoaAdd = {
        "idPessoa": state.idPessoaSelecionadaDiretamente,
        "nomePessoa": pessoaSelecionada[0].nomePessoa,
        "cpfPessoa": pessoaSelecionada[0].numeroCpf === '' ? pessoaSelecionada[0].numeroDocumento : cpfMask(pessoaSelecionada[0].numeroCpf),
        "todaBase": false,
        "dataNascimento": pessoaSelecionada[0].dataNascimento,
        "vinculoLocalPessoa": statusPessoa,
        "sexo": pessoaSelecionada[0].sexo
      }

      let arrayAtualizado = [...state.acessosPessoa, pessoaAdd];
      let contador = 0;
      arrayAtualizado.map(n => {
        contador = contador + 1;
        n.idTemporario = contador;
      });

      state.acessosPessoa = arrayAtualizado;
      return atualizarGestaoAcessoLocal();
    }
    case REMOVE_PERFIL_LOCAL: {
      let idPerfilRemover = parseInt(action.payload);
      let arrayAtualizado = state.acessosPerfil.filter(x => x.idTemporario !== idPerfilRemover);

      let contador = 0;
      arrayAtualizado.map(n => {
        contador = contador + 1;
        n.idTemporario = contador;
      });

      state.acessosPerfil = arrayAtualizado;
      return atualizarGestaoAcessoLocal();
    }
    case ADICIONA_PERFIL_LOCAL_SUCESSO: {

      var perfilSelecionado = state.listaDePerfis.filter(x => x.id === state.idPerfilSelecionado);

      const perfilAdd = {
        "idPerfil": state.idPerfilSelecionado,
        "nomePerfil": perfilSelecionado[0].title,
        "idTemporario": 0,
      }

      let arrayAtualizado = [...state.acessosPerfil, perfilAdd];
      let contador = 0;
      arrayAtualizado.map(n => {
        contador = contador + 1;
        n.idTemporario = contador;
      });

      state.acessosPerfil = arrayAtualizado;
      return atualizarGestaoAcessoLocal();
    }
    case REMOVE_GENERO_LOCAL: {
      let idGeneroRemover = parseInt(action.payload.idGenero);
      let arrayAtualizado = action.payload.removeTodos ? state.acessosGenero.filter(x => x.idPerfil !== action.payload.idPerfil) : state.acessosGenero.filter(x => x.idTemporario !== idGeneroRemover);
      let idPerfilVerificacao = action.payload.removeTodos ? action.payload.idPerfil : state.acessosGenero.filter(x => x.idTemporario === idGeneroRemover)[0].idPerfil;

      if (action.payload.removeTodos) {
        if (idPerfilVerificacao !== 0 && state.acessosIdade.filter(x => x.idPerfil === idPerfilVerificacao).length === 0 &&
          state.acessosPeriodo.filter(x => x.idPerfil === idPerfilVerificacao).length === 0) {
          state.acessosPerfil = state.acessosPerfil.filter(x => x.idPerfil !== idPerfilVerificacao);
        }
      }
      else {
        if (idPerfilVerificacao !== 0 && state.acessosIdade.filter(x => x.idPerfil === idPerfilVerificacao).length === 0 &&
          state.acessosPeriodo.filter(x => x.idPerfil === idPerfilVerificacao).length === 0 && state.acessosGenero.filter(x => x.idPerfil === idPerfilVerificacao).length === 1) {
          state.acessosPerfil = state.acessosPerfil.filter(x => x.idPerfil !== idPerfilVerificacao);
        }
      }



      let contador = 0;
      arrayAtualizado.map(n => {
        contador = contador + 1;
        n.idTemporario = contador;
      });

      state.acessosGenero = arrayAtualizado;
      return atualizarGestaoAcessoLocal();
    }
    case ADICIONA_GENERO_LOCAL_SUCESSO: {

      var perfilSelecionado = state.listaDePerfis.filter(x => x.id === state.idPerfilGeneroSelecionado);
      let idPerfil = 0;
      let nomePerfil = '';

      if (perfilSelecionado && perfilSelecionado.length > 0) {
        idPerfil = state.idPerfilGeneroSelecionado;
        nomePerfil = perfilSelecionado[0].title;

        if (state.acessosPerfil.filter(x => x.idPerfil === idPerfil).length === 0) {
          const perfilAdd = { "idPerfil": state.idPerfilGeneroSelecionado, "nomePerfil": perfilSelecionado[0].title, "idTemporario": 0 };

          state.acessosPerfil = [...state.acessosPerfil, perfilAdd];
        }
      }
      else {
        idPerfil = 0;
        nomePerfil = 'TODOS OS PERFIS';
      }

      const generoAdd = {
        "sexo": state.generoSelecionado,
        "idPerfil": idPerfil,
        "nomePerfil": nomePerfil,
        "idTemporario": 0,
      }

      let arrayAtualizado = generoAdd.nomePerfil === 'TODOS OS PERFIS' ? [...state.acessosGenero.filter(x => x.sexo !== state.generoSelecionado), generoAdd] : [...state.acessosGenero, generoAdd];
      let contador = 0;
      arrayAtualizado.map(n => {
        contador = contador + 1;
        n.idTemporario = contador;
      });

      state.acessosGenero = arrayAtualizado;
      /////state.acessosPessoa = generoAdd.nomePerfil === 'TODOS OS PERFIS' ? state.acessosPessoa.filter(x => x.sexo === state.generoSelecionado && !x.todaBase) : state.acessosPessoa;

      return atualizarGestaoAcessoLocal();
    }
    case REMOVE_IDADE_LOCAL: {
      let idIdadeRemover = parseInt(action.payload);
      let arrayAtualizado = state.acessosIdade.filter(x => x.idTemporario !== idIdadeRemover);
      let idPerfilVerificacao = state.acessosIdade.filter(x => x.idTemporario === idIdadeRemover)[0].idPerfil;

      if (idPerfilVerificacao !== 0 && state.acessosGenero.filter(x => x.idPerfil === idPerfilVerificacao).length === 0 &&
        state.acessosPeriodo.filter(x => x.idPerfil === idPerfilVerificacao).length === 0 && state.acessosIdade.filter(x => x.idPerfil === idPerfilVerificacao).length === 1) {
        state.acessosPerfil = state.acessosPerfil.filter(x => x.idPerfil !== idPerfilVerificacao);
      }

      let contador = 0;
      arrayAtualizado.map(n => {
        contador = contador + 1;
        n.idTemporario = contador;
      });

      state.acessosIdade = arrayAtualizado;
      return atualizarGestaoAcessoLocal();
    }
    case ADICIONA_IDADE_LOCAL_SUCESSO: {

      var perfilSelecionado = state.listaDePerfis.filter(x => x.id === state.idPerfilIdadeSelecionado);
      let idPerfil = 0;
      let nomePerfil = '';

      if (perfilSelecionado && perfilSelecionado.length > 0) {
        idPerfil = state.idPerfilIdadeSelecionado;
        nomePerfil = perfilSelecionado[0].title;

        if (state.acessosPerfil.filter(x => x.idPerfil === idPerfil).length === 0) {
          const perfilAdd = { "idPerfil": state.idPerfilIdadeSelecionado, "nomePerfil": perfilSelecionado[0].title, "idTemporario": 0 };

          state.acessosPerfil = [...state.acessosPerfil, perfilAdd];
        }
      }
      else {
        idPerfil = 0;
        nomePerfil = 'TODOS OS PERFIS';
      }

      if (state.idadeMinima === '') {
        state.idadeMinima = 0;
      }
      else {
        state.idadeMinima = parseInt(state.idadeMinima);
      }

      if (state.idadeMaxima !== '') {
        state.idadeMaxima = parseInt(state.idadeMaxima);
      }

      const idadeAdd = {
        "idadeInicial": state.idadeMinima,
        "idadeFinal": state.idadeMaxima,
        "idPerfil": idPerfil,
        "nomePerfil": nomePerfil,
        "idTemporario": 0,
      }

      let arrayAtualizado = [...state.acessosIdade, idadeAdd];
      let contador = 0;
      arrayAtualizado.map(n => {
        contador = contador + 1;
        n.idTemporario = contador;
      });

      state.acessosIdade = arrayAtualizado;
      return atualizarGestaoAcessoLocal();
    }
    case REMOVE_PESSOA_LOCAL_FULL: {
      let idPessoaRemover = parseInt(action.payload);
      let arrayAtualizado = state.acessosPessoaFull.filter(x => x.idTemporario !== idPessoaRemover);

      let contador = 0;
      arrayAtualizado.map(n => {
        contador = contador + 1;
        n.idTemporario = contador;
      });

      state.acessosPessoaFull = arrayAtualizado;
      return atualizarGestaoAcessoLocal();
    }
    case ADICIONA_PESSOA_LOCAL_FULL_SUCESSO: {

      var pessoaSelecionada = state.listaDePessoasDoClienteCompleto.filter(x => x.id === state.idPessoaSelecionadaDiretamenteAcessoFull);

      if (state.tipoAcessoFullSelecionado == "PESSOA") {

        const pessoaAdd = {
          "idPessoa": state.idPessoaSelecionadaDiretamenteAcessoFull,
          "nomePessoa": pessoaSelecionada[0].nomePessoa,
          "cpfPessoa": pessoaSelecionada[0].numeroCpf === '' ? pessoaSelecionada[0].numeroDocumento : cpfMask(pessoaSelecionada[0].numeroCpf),
          "ehPerfil": false,
        }

        let arrayAtualizado = [...state.acessosPessoaFull, pessoaAdd];
        let contador = 0;
        arrayAtualizado.map(n => {
          contador = contador + 1;
          n.idTemporario = contador;
        });

        state.acessosPessoaFull = arrayAtualizado;
      }
      else {
        var perfilSelecionado = state.listaDePerfis.filter(x => x.id === state.idPerfilAcessoFullSelecionado);

        const pessoaAdd = {
          "nomePessoa": perfilSelecionado[0].title,
          "cpfPessoa": '',
          "idPerfil": state.idPerfilAcessoFullSelecionado,
          "ehPerfil": true,
        }

        let arrayAtualizado = [...state.acessosPessoaFull, pessoaAdd];
        let contador = 0;
        arrayAtualizado.map(n => {
          contador = contador + 1;
          n.idTemporario = contador;
        });

        state.acessosPessoaFull = arrayAtualizado;
      }

      return atualizarGestaoAcessoLocal();
    }
    case REMOVE_PESSOA_LOCAL_BLOQUEIO: {
      let idPessoaRemover = parseInt(action.payload);
      let arrayAtualizado = state.acessosPessoaBloqueio.filter(x => x.idTemporario !== idPessoaRemover);

      let contador = 0;
      arrayAtualizado.map(n => {
        contador = contador + 1;
        n.idTemporario = contador;
      });

      state.acessosPessoaBloqueio = arrayAtualizado;
      return atualizarGestaoAcessoLocal();
    }
    case ADICIONA_PESSOA_LOCAL_BLOQUEIO_SUCESSO: {

      var pessoaSelecionada = state.listaDePessoasDoClienteCompleto.filter(x => x.id === state.idPessoaSelecionadaDiretamenteBloqueioFull);

      if (state.tipoBloqueioFullSelecionado == "PESSOA") {
        const pessoaAdd = {
          "idPessoa": state.idPessoaSelecionadaDiretamenteBloqueioFull,
          "nomePessoa": pessoaSelecionada[0].nomePessoa,
          "cpfPessoa": pessoaSelecionada[0].numeroCpf === '' ? pessoaSelecionada[0].numeroDocumento : cpfMask(pessoaSelecionada[0].numeroCpf),
          "ehPerfil": false,
        }

        let arrayAtualizado = [...state.acessosPessoaBloqueio, pessoaAdd];
        let contador = 0;
        arrayAtualizado.map(n => {
          contador = contador + 1;
          n.idTemporario = contador;
        });

        state.acessosPessoaBloqueio = arrayAtualizado;
        state.acessosPessoaFull = state.acessosPessoaFull.filter(x => x.idPessoa !== state.idPessoaSelecionadaDiretamenteBloqueioFull)
      } else {
        var perfilSelecionado = state.listaDePerfis.filter(x => x.id === state.idPerfilBloqueioFullSelecionado);

        const pessoaAdd = {
          "nomePessoa": perfilSelecionado[0].title,
          "cpfPessoa": '',
          "idPerfil": state.idPerfilBloqueioFullSelecionado,
          "ehPerfil": true,
        }

        let arrayAtualizado = [...state.acessosPessoaBloqueio, pessoaAdd];
        let contador = 0;
        arrayAtualizado.map(n => {
          contador = contador + 1;
          n.idTemporario = contador;
        });

        state.acessosPessoaBloqueio = arrayAtualizado;
        state.acessosPessoaFull = state.acessosPessoaFull.filter(x => x.idPerfil !== state.idPerfilBloqueioFullSelecionado)
      }

      return atualizarGestaoAcessoLocal();
    }
    case ADICIONA_PERIODO_LOCAL_SUCESSO: {
      let idPerfil = 0;
      let descricao = '';
      let idPessoa = 0;
      let ehPerfil = false;
      var pessoaSelecionada = state.listaDePessoasDoClienteCompleto.filter(x => x.id === state.idPessoaSelecionadaDiretamentePeriodo);

      if (state.tipoAcessoPeriodoSelecionado == "PESSOA") {
        idPerfil = 0;
        descricao = state.nomePessoaAdicionarPeriodo;
        idPessoa = state.idPessoaSelecionadaDiretamentePeriodo;
      }
      else {
        ehPerfil = true;
        var perfilSelecionado = state.listaDePerfis.filter(x => x.id === state.idPerfilPeriodoSelecionado);

        if (perfilSelecionado && perfilSelecionado.length > 0) {
          idPerfil = state.idPerfilPeriodoSelecionado;
          descricao = perfilSelecionado[0].title;
          idPessoa = 0;

          if (state.acessosPerfil.filter(x => x.idPerfil === idPerfil).length === 0) {
            const perfilAdd = { "idPerfil": state.idPerfilPeriodoSelecionado, "nomePerfil": perfilSelecionado[0].title, "idTemporario": 0 };

            state.acessosPerfil = [...state.acessosPerfil, perfilAdd];
          }
        }
        else {
          idPerfil = 0;
          descricao = 'TODOS OS PERFIS';
          idPessoa = 0;
        }
      }

      let arrayAtualizado = state.acessosPeriodo.filter(x => x.idTemporario !== state.idTemporarioPeriodo);

      const periodoAdd = {
        "todosDiasSelecionado": state.todosDiasSelecionado,
        "idPerfil": idPerfil,
        "descricao": state.tipoAcessoPeriodoSelecionado == "PESSOA" ? pessoaSelecionada[0].nomePessoa : descricao,
        "segundaAtivo": state.segundaAtivo,
        "tercaAtivo": state.tercaAtivo,
        "quartaAtivo": state.quartaAtivo,
        "quintaAtivo": state.quintaAtivo,
        "sextaAtivo": state.sextaAtivo,
        "sabadoAtivo": state.sabadoAtivo,
        "domingoAtivo": state.domingoAtivo,
        "emQualquerHorario": state.emQualquerHorario,
        "horarios": state.horarios,
        // "horarioInicio": state.horarioInicio,
        // "horarioFim": state.horarioFim,
        // "horarioInicioSegundo": state.horarioInicioSegundo,
        // "horarioFimSegundo": state.horarioFimSegundo,
        // "horarioInicioTerceiro": state.horarioInicioTerceiro,
        // "horarioFimTerceiro": state.horarioFimTerceiro,
        "inicioFimProgramado": state.inicioFimProgramado,
        "dataInicioControle": state.dataInicioControle,
        "dataFimControle": state.dataFimControle,
        "idTemporario": 0,
        "id": state.idPeriodoSelecionado,
        "minutoInicio": state.minutoInicio,
        "minutoFim": state.minutoFim,
        "minutoInicioSegundo": state.minutoInicioSegundo,
        "minutoFimSegundo": state.minutoFimSegundo,
        "minutoInicioTerceiro": state.minutoInicioTerceiro,
        "minutoFimTerceiro": state.minutoFimTerceiro,
        "ehPerfil": ehPerfil,
        "idPessoa": idPessoa,
        "nomePessoa": state.tipoAcessoPeriodoSelecionado == "PESSOA" ? pessoaSelecionada[0].nomePessoa : state.nomePessoaAdicionarPeriodo,
        "cpfPessoa": state.tipoAcessoPeriodoSelecionado == "PESSOA" ? cpfMask(pessoaSelecionada[0].numeroCpf) : cpfMask(state.numeroCpfAdicionarPeriodo)
      }

      arrayAtualizado = [...arrayAtualizado, periodoAdd];
      let contador = 0;
      arrayAtualizado.map(n => {
        contador = contador + 1;
        n.idTemporario = contador;
      });

      state.acessosPeriodo = arrayAtualizado;
      return atualizarGestaoAcessoLocal();
    }
    case NOVO_HORARIO: {
      const idTemporario = state.horarios.length + 1;

      const horarioAdd = {
        "id": 0,
        "idTemporario": idTemporario,
        "horarioInicial": '',
        "horarioFinal": '',
      }

      return {
        ...state,
        horarios: [...state.horarios, horarioAdd],
      }
    }
    case ATUALIZA_HORARIO: {

      var retorno = action.payload;

      var listaDeHorarios = state.horarios.filter(x => x.idTemporario !== retorno.indice);
      var horarioSelecionado = state.horarios.filter(x => x.idTemporario === retorno.indice);

      var horarioAtualizar = {
        "id": horarioSelecionado[0].id,
        "idTemporario": horarioSelecionado[0].idTemporario,
        "horarioInicial": retorno.campoAtualizar === 'horarioInicial' ? horarioMask(retorno.valor) : horarioSelecionado[0].horarioInicial,
        "horarioFinal": retorno.campoAtualizar === 'horarioFinal' ? horarioMask(retorno.valor) : horarioSelecionado[0].horarioFinal,
      };

      listaDeHorarios = [...listaDeHorarios, horarioAtualizar];
      listaDeHorarios = listaDeHorarios.sort((a, b) => Number(a.idTemporario) - Number(b.idTemporario));

      return {
        ...state,
        horarios: listaDeHorarios,
      }
    }
    case REMOVE_HORARIO: {

      return {
        ...state,
        horarios: state.horarios.filter((horario) => horario.idTemporario !== action.payload),
      }
    }
    case EXIBIR_ESTRUTURA_COPIA_ACESSOS: {

      var ambiente = {};
      var pontoAcesso = {};
      var listaHeranca = [];
      var listaReplicacao = [];
      var idPonto = action.payload;
      var naoAdicionarMais = false;

      function possuiDefinicaoNoPonto(props) {

        var possuiAlgumaDefinicaoNoPonto = props.acessosPessoa.length > 0 ||
          props.acessosPerfil.length > 0 ||
          props.acessosGenero.length > 0 ||
          props.acessosIdade.length > 0 ||
          props.acessosPeriodo.length > 0 ||
          props.acessosPessoaFull.length > 0 ||
          props.acessosPessoaBloqueio.length > 0;

        return possuiAlgumaDefinicaoNoPonto;
      }

      function MontaListaPontosParaHeranca(ambientes, pontosAcessoPai) {

        for (var indice in ambientes) {
          ambiente = ambientes[indice];

          if (ambiente.subAmbientes !== undefined && ambiente.subAmbientes.length > 0) {

            if (ambiente.pontosAcesso.length > 0) {

              if (ambiente.pontosAcesso.filter(x => x.idTemporario === idPonto).length > 0) {

                if (pontosAcessoPai !== null) {

                  for (var indice in pontosAcessoPai) {
                    pontoAcesso = pontosAcessoPai[indice];

                    if (!naoAdicionarMais) {
                      if (listaHeranca.filter(x => x.id === pontoAcesso.idTemporario).length === 0 && pontoAcesso.idTemporario !== idPonto) {
                        if (possuiDefinicaoNoPonto(pontoAcesso)) {
                          listaHeranca.push({ id: pontoAcesso.idTemporario, nome: pontoAcesso.descricaoPontoAcesso, marcado: false });
                        }
                      }
                    }
                  }
                }

                for (var indice in ambiente.pontosAcesso) {
                  pontoAcesso = ambiente.pontosAcesso[indice];

                  if (!naoAdicionarMais) {
                    if (listaHeranca.filter(x => x.id === pontoAcesso.idTemporario).length === 0 && pontoAcesso.idTemporario !== idPonto) {
                      /*                       if (possuiDefinicaoNoPonto(pontoAcesso)) {
                                              listaHeranca.push({ id: pontoAcesso.idTemporario, nome: pontoAcesso.descricaoPontoAcesso, marcado: false });
                                            } */

                      if (!possuiDefinicaoNoPonto(pontoAcesso)) {
                        listaReplicacao.push({ id: pontoAcesso.idTemporario, nome: pontoAcesso.descricaoPontoAcesso, marcado: false });
                      }
                    }
                  }


                }

                naoAdicionarMais = true;
                break;

              }
              else {
                MontaListaPontosParaHeranca(ambiente.subAmbientes, ambiente.pontosAcesso);
              }
            }
            else {
              MontaListaPontosParaHeranca(ambiente.subAmbientes, null);
            }
          }

          if (ambiente.pontosAcesso && ambiente.pontosAcesso.length > 0) {

            if (ambiente.pontosAcesso.filter(x => x.idTemporario === idPonto).length > 0) {

              if (pontosAcessoPai !== null) {

                for (var indice in pontosAcessoPai) {
                  pontoAcesso = pontosAcessoPai[indice];

                  if (!naoAdicionarMais) {
                    if (listaHeranca.filter(x => x.id === pontoAcesso.idTemporario).length === 0 && pontoAcesso.idTemporario !== idPonto) {
                      if (possuiDefinicaoNoPonto(pontoAcesso)) {
                        listaHeranca.push({ id: pontoAcesso.idTemporario, nome: pontoAcesso.descricaoPontoAcesso, marcado: false });
                      }
                    }
                  }
                }
              }

              for (var indice in ambiente.pontosAcesso) {
                pontoAcesso = ambiente.pontosAcesso[indice];

                if (!naoAdicionarMais) {
                  if (listaHeranca.filter(x => x.id === pontoAcesso.idTemporario).length === 0 && pontoAcesso.idTemporario !== idPonto) {
                    /*                     if (possuiDefinicaoNoPonto(pontoAcesso)) {
                                          listaHeranca.push({ id: pontoAcesso.idTemporario, nome: pontoAcesso.descricaoPontoAcesso, marcado: false });
                                        } */

                    if (!possuiDefinicaoNoPonto(pontoAcesso)) {
                      listaReplicacao.push({ id: pontoAcesso.idTemporario, nome: pontoAcesso.descricaoPontoAcesso, marcado: false });
                    }
                  }
                }
              }

              naoAdicionarMais = true;

              break;

            }
            else {
              MontaListaPontosParaHeranca(ambiente.subAmbientes, ambiente.pontosAcesso);
            }
          }

        }
      }

      MontaListaPontosParaHeranca(state.listaAmbientesDoLocal, null);

      return {
        ...state,
        exibeEstruturaCopiaAcessos: !state.exibeEstruturaCopiaAcessos,
        listaDePontosHeranca: listaHeranca,
        listaDePontosReplicacao: listaReplicacao
      }
    }
    case REMOVE_PERIODO_LOCAL: {
      let idPeriodoRemover = parseInt(action.payload);
      let arrayAtualizado = state.acessosPeriodo.filter(x => x.idTemporario !== idPeriodoRemover);
      let idPerfilVerificacao = state.acessosPeriodo.filter(x => x.idTemporario === idPeriodoRemover)[0].idPerfil;

      if (idPerfilVerificacao !== 0 && state.acessosGenero.filter(x => x.idPerfil === idPerfilVerificacao).length === 0 &&
        state.acessosIdade.filter(x => x.idPerfil === idPerfilVerificacao).length === 0 && state.acessosPeriodo.filter(x => x.idPerfil === idPerfilVerificacao).length === 1) {
        state.acessosPerfil = state.acessosPerfil.filter(x => x.idPerfil !== idPerfilVerificacao);
      }

      let contador = 0;
      arrayAtualizado.map(n => {
        contador = contador + 1;
        n.idTemporario = contador;
      });

      state.acessosPeriodo = arrayAtualizado;
      return atualizarGestaoAcessoLocal();
    }
    case REMOVE_HORARIO_PERIODO: {
      let idHorarioRemover = parseInt(action.payload);

      var horarioInicio = state.horarioInicio;
      var horarioFim = state.horarioFim;

      var minutoInicio = state.minutoInicio;
      var minutoFim = state.minutoFim;

      var horarioInicioSegundo = state.horarioInicioSegundo;
      var horarioFimSegundo = state.horarioFimSegundo;

      var minutoInicioSegundo = state.minutoInicioSegundo;
      var minutoFimSegundo = state.minutoFimSegundo;

      var horarioInicioTerceiro = state.horarioInicioTerceiro;
      var horarioFimTerceiro = state.horarioFimTerceiro;

      var minutoInicioTerceiro = state.minutoInicioTerceiro;
      var minutoFimTerceiro = state.minutoFimTerceiro;


      if (idHorarioRemover === 1) {
        horarioInicio = '';
        horarioFim = '';
        minutoInicio = '';
        minutoFim = '';
      }

      if (idHorarioRemover === 2) {
        horarioInicioSegundo = '';
        horarioFimSegundo = '';
        minutoInicioSegundo = '';
        minutoFimSegundo = '';
      }

      if (idHorarioRemover === 3) {
        horarioInicioTerceiro = '';
        horarioFimTerceiro = '';
        minutoInicioTerceiro = '';
        minutoFimTerceiro = '';
      }

      return {
        ...state,
        horarioInicio: horarioInicio,
        horarioFim: horarioFim,
        horarioInicioSegundo: horarioInicioSegundo,
        horarioFimSegundo: horarioFimSegundo,
        horarioInicioTerceiro: horarioInicioTerceiro,
        horarioFimTerceiro: horarioFimTerceiro,
        minutoInicio: minutoInicio,
        minutoFim: minutoFim,
        minutoInicioSegundo: minutoInicioSegundo,
        minutoFimSegundo: minutoFimSegundo,
        minutoInicioTerceiro: minutoInicioTerceiro,
        minutoFimTerceiro: minutoFimTerceiro,
      }
    }
    case EDITA_PERIODO_LOCAL: {
      let idPeriodoEditar = parseInt(action.payload);
      let objetoEdicao = state.acessosPeriodo.filter(x => x.idTemporario === idPeriodoEditar)[0];

      return {
        ...state,
        todosDiasSelecionado: objetoEdicao.todosDiasSelecionado,
        idPerfilPeriodoSelecionado: objetoEdicao.idPerfil,
        segundaAtivo: objetoEdicao.segundaAtivo,
        tercaAtivo: objetoEdicao.tercaAtivo,
        quartaAtivo: objetoEdicao.quartaAtivo,
        quintaAtivo: objetoEdicao.quintaAtivo,
        sextaAtivo: objetoEdicao.sextaAtivo,
        sabadoAtivo: objetoEdicao.sabadoAtivo,
        domingoAtivo: objetoEdicao.domingoAtivo,
        emQualquerHorario: objetoEdicao.emQualquerHorario,
        horarioInicio: objetoEdicao.horarioInicio,
        horarioFim: objetoEdicao.horarioFim,
        horarioInicioSegundo: objetoEdicao.horarioInicioSegundo,
        horarioFimSegundo: objetoEdicao.horarioFimSegundo,
        horarioInicioTerceiro: objetoEdicao.horarioInicioTerceiro,
        horarioFimTerceiro: objetoEdicao.horarioFimTerceiro,
        inicioFimProgramado: objetoEdicao.inicioFimProgramado,
        dataInicioControle: objetoEdicao.dataInicioControle,
        dataFimControle: objetoEdicao.dataFimControle,
        idTemporarioPeriodo: objetoEdicao.idTemporario,
        idPeriodoSelecionado: objetoEdicao.id,
        minutoInicio: objetoEdicao.minutoInicio,
        minutoFim: objetoEdicao.minutoFim,
        minutoInicioSegundo: objetoEdicao.minutoInicioSegundo,
        minutoFimSegundo: objetoEdicao.minutoFimSegundo,
        minutoInicioTerceiro: objetoEdicao.minutoInicioTerceiro,
        minutoFimTerceiro: objetoEdicao.minutoFimTerceiro,
        tipoAcessoPeriodoSelecionado: objetoEdicao.ehPerfil == true ? 'PERFIL' : 'PESSOA',
        idPessoaAdicionarPeriodo: objetoEdicao.idPessoa,
        nomePessoaAdicionarPeriodo: objetoEdicao.nomePessoa,
        numeroCpfAdicionarPeriodo: objetoEdicao.cpfPessoa
      }
    }
    case CANCELA_PERIODO_LOCAL: {

      return {
        ...state,
        idPerfilPeriodoSelecionado: 0,
        todosDiasSelecionado: true,
        segundaAtivo: true,
        tercaAtivo: true,
        quartaAtivo: true,
        quintaAtivo: true,
        sextaAtivo: true,
        sabadoAtivo: true,
        domingoAtivo: true,
        emQualquerHorario: true,
        horarioInicio: '',
        horarioFim: '',
        horarioInicioSegundo: '',
        horarioFimSegundo: '',
        horarioInicioTerceiro: '',
        horarioFimTerceiro: '',
        inicioFimProgramado: false,
        dataInicioControle: null,
        dataFimControle: null,
        idTemporarioPeriodo: 0,
        idPeriodoSelecionado: 0,
        minutoInicio: '',
        minutoFim: '',
        minutoInicioSegundo: '',
        minutoFimSegundo: '',
        minutoInicioTerceiro: '',
        minutoFimTerceiro: '',
        tipoAcessoPeriodoSelecionado: "PERFIL",
        nomePessoaAdicionarPeriodo: '',
        idPessoaAdicionarPeriodo: '',
        numeroCpfAdicionarPeriodo: ''
      }
    }
    case ALTERACAO_CHECK_TODOS_DIAS_SEMANA: {
      let todosDiasSemana = action.payload;

      return {
        ...state,
        todosDiasSelecionado: todosDiasSemana,
        segundaAtivo: todosDiasSemana == true ? true : false,
        tercaAtivo: todosDiasSemana == true ? true : false,
        quartaAtivo: todosDiasSemana == true ? true : false,
        quintaAtivo: todosDiasSemana == true ? true : false,
        sextaAtivo: todosDiasSemana == true ? true : false,
        sabadoAtivo: todosDiasSemana == true ? true : false,
        domingoAtivo: todosDiasSemana == true ? true : false,
      }
    }
    case ALTERACAO_CHECK_EM_QUALQUER_HORARIO: {
      let emQualquerHorario = action.payload;

      return {
        ...state,
        emQualquerHorario: emQualquerHorario,
        horarioInicio: emQualquerHorario == true ? '' : state.horarioInicio,
        horarioFim: emQualquerHorario == true ? '' : state.horarioFim,
        horarioInicioSegundo: emQualquerHorario == true ? '' : state.horarioInicioSegundo,
        horarioFimSegundo: emQualquerHorario == true ? '' : state.horarioFimSegundo,
        horarioInicioTerceiro: emQualquerHorario == true ? '' : state.horarioInicioTerceiro,
        horarioFimTerceiro: emQualquerHorario == true ? '' : state.horarioFimTerceiro,
        minutoInicio: emQualquerHorario == true ? '' : state.minutoInicio,
        minutoFim: emQualquerHorario == true ? '' : state.minutoFim,
        minutoInicioSegundo: emQualquerHorario == true ? '' : state.minutoInicioSegundo,
        minutoFimSegundo: emQualquerHorario == true ? '' : state.minutoFimSegundo,
        minutoInicioTerceiro: emQualquerHorario == true ? '' : state.minutoInicioTerceiro,
        minutoFimTerceiro: emQualquerHorario == true ? '' : state.minutoFimTerceiro,
      }
    }
    case ALTERACAO_CHECK_INICIO_FIM_PROGRAMADO: {
      let inicioFimProgramado = action.payload;

      return {
        ...state,
        inicioFimProgramado: inicioFimProgramado,
        dataInicioControle: inicioFimProgramado == true ? state.dataInicioControle : null,
        dataFimControle: inicioFimProgramado == true ? state.dataFimControle : null,
      }
    }
    case ADICIONA_TODA_BASE_SUCESSO: {
      var registroDuplicado = state.acessosPessoa.filter(x => x.todaBase === true).length > 0;

      if (registroDuplicado) {
        return {
          ...state,
          numeroCpfAdicionar: '',
          nomePessoaAdicionar: '',
          idPessoaAdicionar: '',
          loader: false,
        }
      }

      const pessoaAdd = {
        "idPessoa": 0,
        "nomePessoa": "TODA A BASE DE USUÁRIOS",
        "cpfPessoa": state.listaDePessoasDoCliente.length + ' pessoa(s)',
        "todaBase": true,
      }

      let arrayAtualizado = [...state.acessosPessoa, pessoaAdd];
      let perfisAtualizado = [];
      let idPerfilVerificacao = 0;
      let contador = 0;
      arrayAtualizado.map(n => {
        contador = contador + 1;
        n.idTemporario = contador;
      });

      state.acessosPessoa = arrayAtualizado;
      state.acessosPerfil = []; // DELETAR AQUI TODOS PERFIS QUE NÃO ESTÃO SENDO REFERENCIADOS EM GENERO, IDADE E PERIODO.

      for (var indice in state.acessosPerfil) {
        idPerfilVerificacao = state.acessosPerfil[indice].idPerfil;

        if (state.acessosGenero.filter(x => x.idPerfil).length > 0 ||
          state.acessosIdade.filter(x => x.idPerfil).length > 0 ||
          state.acessosPeriodo.filter(x => x.idPerfil).length > 0) {
          perfisAtualizado = [...perfisAtualizado, state.acessosPerfil[indice]];
        }
      }

      state.acessosPerfil = perfisAtualizado;

      return atualizarGestaoAcessoLocal();
    }
    default:
      return state;
  }
}
